import styled from 'styled-components';
import bg from '../../assets/Game/Earn/earn_bg.webp';
import bg02 from '../../assets/Game/Earn/earn_bg_02.png';
import bg03 from '../../assets/Game/Earn/earn_bg_03.webp';

export const EarnContainer = styled.div`
  padding-top: 60px;
  .img-title-mission {
    max-width: 350px;
    margin: 0 auto;
    margin-top: 20px;
    img {
      width: 100%;
    }
  }
`;
export const EarnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 190px);
  height: calc(100svh - 190px);
`;
export const EarnContent = styled.div`
  color: var(--Neutral-50, #f7f7f8);
  text-align: center;
  text-shadow: 0px 2.164px 1.082px rgba(0, 0, 0, 0.42);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #6590ff;
  font-family: 'ErasBoldITC';
  font-size: 34.621px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 43.276px */
  /* background: url(${bg}) center / auto 453px no-repeat; */
  min-height: 553px;
  width: 100%;
`;
export const EarnDaily = styled.div`
  max-width: 350px;
  width: 100%;
  margin: 0 auto 10px;
  padding-top: 50px;
  h2 {
    color: var(--Neutral-50, #f7f7f8);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--Orange-500, #dc622e);
    font-family: 'ErasBoldITC';
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 8px;
  }
`;
export const EarnDailyInner = styled.div`
  background: #ffc700 url(${bg02}) center / cover no-repeat;
  border-radius: 12px;
  border: 2px solid #fff;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
`;
export const EarnDailyList = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
  .earm-item {
    width: 60px;
  }
  .item-inner {
    width: 60px;
    border-radius: 9.31px;
    background: #ff8039;
    line-height: 1;
    padding: 3px 0;
    h3 {
      color: #fff;
      text-align: center;
      -webkit-text-stroke-width: 0.1;
      -webkit-text-stroke-color: #ff5e03;
      font-family: 'ErasBoldITC';
      font-size: 14.542px;
      margin-bottom: -5px;
      text-shadow: none;
    }
    img {
      width: 21px;
      margin-bottom: -3px;
    }
    p {
      color: #fff;
      text-align: center;
      text-shadow: 0px 1.953px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 0.1;
      -webkit-text-stroke-color: #ff5e03;
      font-family: 'ErasBoldITC';
      font-size: 13px;
    }
    h3,
    p {
      line-height: 1;
    }
  }
  .item-claimed {
    width: 60px;
    border-radius: 9.31px;
    background: #9e9e9e;
    line-height: 1;
    padding: 3px 0;
    cursor: not-allowed;
    h3 {
      color: #fff;
      text-align: center;
      -webkit-text-stroke-width: 0.1;
      -webkit-text-stroke-color: #b3aeae;
      font-family: 'ErasBoldITC';
      font-size: 14.542px;
      margin-bottom: -5px;
      text-shadow: none;
    }
    img {
      width: 21px;
      margin-bottom: -3px;
      opacity: 0.5;
    }
    p {
      color: #fff;
      text-align: center;
      text-shadow: 0px 1.953px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 0.1;
      -webkit-text-stroke-color: #b3aeae;
      font-family: 'ErasBoldITC';
      font-size: 13px;
    }
    h3,
    p {
      line-height: 1;
    }
  }
`;

export const EarnDailyListParent = styled.div`
  width: 320px;
  overflow: scroll;
  margin: 0 auto;
`;

export const EarnDailyButton = styled.button`
  display: block;
  width: 100%;
  color: #fff;
  text-align: center;
  text-shadow: 0px 1.509px 0px #d94500;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fd7f01;
  font-family: 'ErasBoldITC';
  font-size: 16px;
  line-height: 1;
  border-radius: 6.79px;
  border: 1.509px solid #fff;
  background: linear-gradient(180deg, #ffd902 0%, #ff5602 100%);
  box-shadow: 0px -5px 0px 0px #fd3801 inset;
  padding: 8px;
  cursor: pointer;
`;

export const DailyDetail = styled.div`
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  max-height: calc(100vh - 360px);
  overflow: auto;
`;
export const DailyDetailList = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    border-radius: 12px;
    width: 100%;
    padding: 0px 10px;
    border: 2px solid #0019ff;
    background: #a0b5ff;
    box-shadow: 0px 0px 12.425px 0px rgba(0, 0, 0, 0.47);
    box-sizing: border-box;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    .content-left {
      display: flex;
      align-items: center;
      .img-claimed {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8.8px;
        background: #ff9f0e;
      }
      .img-task {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8.8px;
        background: #3e6aab;
      }
      .title {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        p {
          color: #3d55ad;
          font-size: 12px;
          font-style: normal;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 400;
          line-height: 120%; /* 12px */
          letter-spacing: -0.4px;
          text-transform: capitalize;
          -webkit-text-stroke-width: 0;
          -webkit-text-stroke-color: none;
          text-align: left;
          margin-top: -3px;
          margin-left: 5px;
          text-shadow: none;
        }
      }
      img {
        width: 44px;
      }
      h3 {
        color: var(--Neutral-50, #f7f7f8);
        -webkit-text-stroke-width: 0.9333948493003845;
        -webkit-text-stroke-color: #6590ff;
        font-family: 'ErasBoldITC';
        font-size: 16px;
        margin-left: 4px;
        line-height: 20px;
        text-shadow: none;
        text-align: left;
        text-transform: uppercase;
      }
    }
    .content-right {
      background: url(${bg03}) center / cover no-repeat;
      max-width: 72px;
      width: 100%;
      text-align: center;
      padding: 3px 0;
      margin: -1px 0;
      .icon-coin {
        margin: -3px 0;
        display: block;
        line-height: 1;
      }
      .point,
      button {
        display: block;
      }
      .icon-point {
        width: 21px;
      }
      .point {
        color: #fff;
        text-align: center;
        text-shadow: none;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #3c72ff;
        font-family: 'ErasBoldITC';
        font-size: 14px;
        line-height: 1;
        margin-bottom: 3px;
      }
      button {
        border-radius: 7px;
        border: 1px solid #1000c3;
        background: linear-gradient(180deg, #7aafff 0%, #236ee0 100%);
        box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset;
        padding: 4px 15px;
        color: #fff;
        text-align: center;
        text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #3c72ff;
        font-family: 'ErasBoldITC';
        font-size: 13px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
  .task-claim {
    border-radius: 12px;
    border: 2px solid #d94500;
    background: #ffd15b;
    .content-left {
      h4 {
        color: #d94500;
        font-family: 'ErasBoldITC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 17.5px */
        margin: 0;
        -webkit-text-stroke-width: 0;
        -webkit-text-stroke-color: none;
        margin-left: 4px;
        text-shadow: none;
      }
      h3 {
        color: var(--Neutral-50, #f7f7f8);
        -webkit-text-stroke-width: 0.5;
        -webkit-text-stroke-color: #fd7f01;
        font-family: 'ErasBoldITC';
        font-size: 18px;
        margin-left: 4px;
        line-height: 20px;
        text-shadow: none;
      }
      .title {
        p {
          color: #d94500;
        }
      }
    }
    .content-right {
      background: url('/static/bg-earn.png') center / cover no-repeat;
      .point {
        color: #fff;
        text-align: center;
        text-shadow: none;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fd7f01;
        font-family: 'ErasBoldITC';
        font-size: 14px;
        line-height: 1;
        margin-bottom: 3px;
      }
      button {
        display: block;
        color: #fff;
        text-align: center;
        text-shadow: 0px 1.509px 0px #d94500;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fd7f01;
        font-family: 'ErasBoldITC';
        font-size: 13px;
        line-height: 1;
        border-radius: 6.79px;
        border: 1.509px solid #fff;
        background: linear-gradient(180deg, #ffd902 0%, #ff5602 100%);
        box-shadow: 0px -5px 0px 0px #fd3801 inset;
        padding: 5px 10px;
        cursor: pointer;
      }
    }
  }
`;

export const MysteryTask = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: flex-end;
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 5px;
  margin-top: 40px;
  box-sizing: border-box;
`;

export const MysteryTaskItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const MysteryTaskTitle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 3px;
  margin-bottom: 3px;
  .img-random {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    max-width: 14px;
    img {
      width: 100%;
    }
  }
  h4 {
    color: var(--Neutral-50, #000);
    -webkit-text-stroke-width: 0.1;
    -webkit-text-stroke-color: var(--Orange-500, #fff);
    font-family: 'CodaCaption';
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    font-weight: 600;
    width: 100%;
  }
`;

export const MysteryTaskRandom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  border-radius: 6px;
  width: 100%;
  padding: 5px 10px;
  border: 1.69px solid #fff;
  background: #000;
  box-shadow: 0px 0px 12.425px 0px rgba(0, 0, 0, 0.47);
  box-sizing: border-box;
  .icon-all-coin {
    max-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
`;

export const MysteryTaskRandomTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  h4 {
    color: #fff8f8;
    font-family: 'ErasBoldITC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.48px;
    text-shadow: none;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: none;
    text-align: left;
  }
  .img-k {
    max-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const MysteryTaskRandomPoint = styled.div`
  position: relative;
  h2 {
    color: #fd0;
    text-align: center;
    text-shadow: none;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: #482200;
    font-family: 'ErasBoldITC';
    font-size: 12.816px;
    font-style: normal;
    font-weight: 400;
    line-height: 89%; /* 11.406px */
    letter-spacing: -0.769px;
    margin-top: -10px;
  }
`;

export const IconDailyBonus = styled.div`
  .img-daily {
    max-width: 90px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    img {
      width: 100%;
      height: 100%;
      margin-bottom: -7px;
    }
  }
`;
