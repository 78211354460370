import React, { useState } from 'react';
import {
  ButtonConfirm,
  ModalContainer,
  ModalContent,
  ModalContentInner,
  ModalContentTitle,
  ModalCount,
  ModalReceive,
  ModalText,
  ModalWrapper,
} from './modal';
import icnModal01 from '../../../assets/Game/Assets/icn_modal_01.png';
import img01 from '../../../assets/Game/Assets/modal_img_01.png';
import InputCommon from '../../../components/Common/Input';
import icnKibbleToken from '../../../assets/Game/Assets/icon_token_kibble.png';
import icnCoin from '../../../assets/Game/coin_base.png';
import icnClose from '../../../assets/Game/button_close.png';
import buttonDecrease from '../../../assets/Game/Assets/button_decrease.png';
import buttonIncrease from '../../../assets/Game/Assets/button_increase.png';
import { ButtonClose } from '../../Leaderboard/styled';
import title from '../../../assets/Game/Assets/Shop/title_modal.png';

const ModalProduct = ({ item, hiddenShowModalProduct, index }: any) => {
  const [value, setValue] = useState(1);
  const handleIncrease = () => {
    setValue(value + 1);
  };
  const handleDecrease = () => {
    if (value > 1) {
      setValue(value - 1);
    } else {
      setValue(1);
    }
  };
  const onChangeValue = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <ModalContainer>
      <ModalWrapper>
        <ModalContentTitle>
          <img src={title} alt="Confirmation" />
        </ModalContentTitle>
        <ModalContentInner>
          <ButtonClose onClick={hiddenShowModalProduct}>
            <img src={icnClose} alt="Close" loading="lazy" />
          </ButtonClose>
          <ModalContent>
            <ModalCount>
              <img
                className="image-left"
                src={item.imgSmall}
                alt=""
                loading="eager"
              />
              <div className="content-right">
                <span className="count-number">
                  {item.priceShort} Pack {item.id}
                </span>
                <InputCommon
                  prefix={
                    <div className="decrease" onClick={handleDecrease}>
                      <img src={buttonDecrease} alt="Decrease" loading="lazy" />
                    </div>
                  }
                  suffix={
                    <div className="increase" onClick={handleIncrease}>
                      <img src={buttonIncrease} alt="Increase" loading="lazy" />
                    </div>
                  }
                  type="number"
                  value={value}
                  onChange={onChangeValue}
                />
              </div>
            </ModalCount>
            <ModalReceive>
              <p className="text-receive">You’ll Receive</p>
              <div className="content-receive">
                <img src={icnKibbleToken} alt="Kibble" />
                <span>{value * item.receive}</span>
              </div>
            </ModalReceive>
            <ButtonConfirm>
              <img src={icnCoin} alt="Coin" />
              {value * item.priceModal}
            </ButtonConfirm>
          </ModalContent>
        </ModalContentInner>
      </ModalWrapper>
    </ModalContainer>
  );
};

export default ModalProduct;
