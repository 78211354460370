import { ButtonCommonStyle } from './styled';

type ButtonType = {
  text: string;
  onClick?: () => void;
  textColor?: string;
  bgColor?: string;
  boxShadowColor?: string;
  width?: string;
};

export default function ButtonCommonV2({
  text,
  onClick,
  textColor,
  bgColor,
  boxShadowColor,
  width,
}: ButtonType) {
  return (
    <ButtonCommonStyle
      onClick={onClick}
      textColor={textColor}
      bgColor={bgColor}
      boxShadowColor={boxShadowColor}
      width={width}
    >
      <span>{text}</span>
    </ButtonCommonStyle>
  );
}
