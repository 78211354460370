import avatar from '../../assets/Game/avatar.jpg';
import { GameProgressBar } from '../../page/Clicker/styled';
import {
  GameHeaderWrapper,
  HeaderCoinPoint,
  HeaderInfo,
  InfoUser,
  UserInfo,
  UserName,
} from './styledv2';
import {
  getDataEnergy,
  getDataLeaderBoard,
  getDataListFriends,
  getDataListTask,
  getDataMaxPoint,
  getDataPoint,
  getListPointOfInviteFriends,
  getToastMess,
} from '../../redux/features/pointSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from '../../redux/store';
import { useInitData } from '@tma.js/sdk-react';
import ToastMessage from '../Toast';
import { convertBigNumberToText } from '../../utils/convertBigNumber';
import { convertFormatNumber } from '../../utils/convertFormatNumber';
import bg_info_3 from '../../assets/Game/bg_info_3.png';

const GameHeaderV2 = () => {
  const {
    getPoints,
    infoUser,
    energy,
    listTask,
    listFriends,
    leaderBoard,
    listPointOfInviteFriends,
    toastMessage,
    userLevel,
    maxPoint,
  } = useSelector((state: any) => state.point);

  const [energyPoint, setEnergyPoint] = useState(0);
  const [isSetEnergy, setIsSetEnergy] = useState(false);

  const dispatch = useDispatch();
  const dataUserTele: any = useInitData();

  const {
    initData: { user },
  } = dataUserTele;

  useEffect(() => {
    // Energy refresh 1 every 1ms
    const intervalEngryPoint = setInterval(() => {
      setEnergyPoint((prev) => Math.min(prev + 1, infoUser?.energy_limit));
    }, 1000);
    if (listTask.length === 0) {
      dispatch(getDataListTask());
    }
    if (!listFriends) {
      dispatch(getDataListFriends());
    }
    if (!leaderBoard) {
      dispatch(getDataLeaderBoard());
    }
    if (!listPointOfInviteFriends || listPointOfInviteFriends.length === 0) {
      dispatch(getListPointOfInviteFriends());
    }
    return () => {
      clearInterval(intervalEngryPoint);
    };
  }, []);

  useEffect(() => {
    // Save energyPoint to localStorage whenever it changes
    dispatch(getDataEnergy(energyPoint));
  }, [energyPoint]);

  useEffect(() => {
    setEnergyPoint(energy);
  }, [energy]);

  useEffect(() => {
    if (infoUser && !isSetEnergy) {
      setEnergyPoint(infoUser.energy);
      setIsSetEnergy(true);
      dispatch(getDataPoint(infoUser.points));
      dispatch(getDataMaxPoint(infoUser.max_point));
      localStorage.setItem('localPoint', infoUser.points);
    }
  }, [infoUser]);

  useEffect(() => {
    if (toastMessage) {
      setTimeout(() => {
        dispatch(getToastMess(null));
      }, 2000);
    }
  }, [toastMessage]);

  return (
    <GameHeaderWrapper>
      {toastMessage && <ToastMessage data={toastMessage} />}
      <HeaderInfo>
        <figure className="background-header">
          <img src="/images/Header/bg_header.png" alt="" />
        </figure>
        <figure className="avatar-kibble">
          <img src={avatar} alt="icon" loading="lazy" />
        </figure>
        <InfoUser>
          <UserName>
            <p>{`${user.firstName} ${user.lastName}`}</p>
          </UserName>
          <UserInfo>
            <div className="bg-infor">
              <img src={bg_info_3} alt="" />
            </div>
            <div>
              <span className="level">Level</span>
              <GameProgressBar Progress={0}>
                <span
                  style={{
                    width: userLevel
                      ? `${
                          (Number(maxPoint) * 100) / userLevel.pointNextLevel >
                          100
                            ? 100
                            : (Number(maxPoint) * 100) /
                              userLevel.pointNextLevel
                        }%`
                      : 0,
                    position: 'relative',
                  }}
                ></span>
              </GameProgressBar>
              <h1>{userLevel && userLevel.lv}</h1>
            </div>
          </UserInfo>
        </InfoUser>
      </HeaderInfo>
      <HeaderCoinPoint>
        <div className="img-header-point">
          <img src="/static/img-header-point.png" alt="point" />
        </div>
        <p>
          {getPoints
            ? getPoints > 1000000
              ? convertBigNumberToText(Number(getPoints))
              : convertFormatNumber(Number(getPoints))?.replaceAll('.00', '')
            : 0}
        </p>
      </HeaderCoinPoint>
    </GameHeaderWrapper>
  );
};

export default GameHeaderV2;
