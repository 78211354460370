import { useContext, useEffect, useState } from 'react';
import { useDispatch } from '../../../redux/store';
import { ModalContainer } from '../../Assets/Modal/modal';
import {
  AllDailyInWeek,
  DailyInWeek,
  ModalDailyContainer,
} from './styleModalDailyv2';
import { KIBBLE_API } from '../../../services/api';
import { getDailyMess, getDataInfoUser, getToastMess } from '../../../redux/features/pointSlice';
import { convertFormatNumber } from '../../../utils/convertFormatNumber';
import { useInitData } from '@tma.js/sdk-react';

type ModalDailyType = {
  onClose: () => void;
  infoDailyChecking?: any;
  onSubmit?: () => void;
};

export default function ModalDaily({
  onClose,
  onSubmit,
  infoDailyChecking,
}: ModalDailyType) {

  const dispatch = useDispatch();
  const dataUserTele: any = useInitData();

  const {
    initData: { user },
  } = dataUserTele;

  const [dayToCheckin, setDayToCheckin] = useState(0);

  const handleExcuteDailyCheckIn = async (data: any) => {
    try {
      await KIBBLE_API.dailyCheckin();
      dispatch(getToastMess({
        message: `Got +${convertFormatNumber(Number(data?.point))?.replaceAll(".00", "")} points`,
        status: true,
      }))
      dispatch(getDailyMess(data));
      dispatch(getDataInfoUser(user));
      onClose();
    } catch (error) {
      console.log("====================================");
      console.log("handleExcuteDailyCheckIn err", error);
      console.log("====================================");
      onClose();
      dispatch(
        getToastMess({
          message: "Try it the next day",
          status: false,
        })
      );
    }
  };

  useEffect(() => {
    if (
      infoDailyChecking &&
      infoDailyChecking?.dailyCheckin &&
      infoDailyChecking?.dailyCheckin?.checkin_count < 7
    ) {
      setDayToCheckin(infoDailyChecking?.dailyCheckin?.checkin_count);
    } else if (
      infoDailyChecking &&
      infoDailyChecking?.dailyCheckin &&
      infoDailyChecking?.dailyCheckin?.checkin_count === 7
    ) {
      setDayToCheckin(0);
    }
  }, [infoDailyChecking]);

  return (
    <ModalContainer>
      <ModalDailyContainer>
        <div className="img-close" onClick={onClose}>
          <img src="/static/img-close.png" alt="close" />
        </div>
        <div className="img-title">
          <img src="/static/img-title-modal-daily.png" alt="title" />
        </div>
        <h1>
          Return tomorrow for the <br /> next reward!
        </h1>
        <AllDailyInWeek>
          {listDaily.map((item: any, index: number) => {
            return (
              <DailyInWeek 
                onClick={() => {
                  index === dayToCheckin && handleExcuteDailyCheckIn(item);
                }}
                isActive={index === dayToCheckin || index > dayToCheckin}>
                <div className="img-check">
                  {index < dayToCheckin || index > dayToCheckin ? (
                    <img src="/static/icon-lock.png" alt="lock" />
                  ) : (
                    <img src="/static/icon-check.png" alt="check" />
                  )}
                </div>
                <h1>DAY {item?.day}</h1>
                <div className="point">
                  <div className="img-coins">
                    {index === dayToCheckin || index > dayToCheckin ? (
                      <img src="/static/icon-all-coin.png" alt="" />
                    ) : (
                      <img src="/static/icon-all-coin-dark.png" alt="lock" />
                    )}
                  </div>
                  <h2>
                  {convertFormatNumber(Number(item?.value))?.replaceAll(
                    ".00",
                    ""
                  )}
                  </h2>
                </div>
              </DailyInWeek>
            );
          })}
        </AllDailyInWeek>
      </ModalDailyContainer>
    </ModalContainer>
  );
}

const listDaily = [
  {
    day: '1',
    point: 10000,
    value: 10000,
  },
  {
    day: '2',
    point: 20000,
    value: 20000,
  },
  {
    day: '3',
    point: 30000,
    value: 30000,
  },
  {
    day: '4',
    point: 40000,
    value: 40000,
  },
  {
    day: '5',
    point: '50,000',
    value: 50000,
  },
  {
    day: '6',
    point: 60000,
    value: 60000,
  },
  {
    day: '7',
    point: 70000,
    value: 70000,
  },
];
