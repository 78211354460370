import styled from "styled-components";
import bg_info_1 from "../../assets/Game/bg_info_1.svg";
import bg_info_2 from "../../assets/Game/bg_info_2.svg";
import { GameProgressBar } from "../../page/Clicker/styled";

export const GameHeaderWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  top: 0;
  z-index: 10;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 10px;
  /* border-bottom: 4px solid #ce6000; */
  /* background: #ffb854; */
  /* box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.5); */
  /* &::before {
    height: 100%;
    width: 100%;
    content: '';
    position: absolute;
    background: url('/static/bg-header.png') lightgray 50% / cover no-repeat;
    mix-blend-mode: soft-light;
    left: 0;
    top: 0;
    z-index: -1;
  } */
`;
export const HeaderInfo = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  .avatar-kibble {
    position: relative;
    width: 54px;
    height: 50px;
    padding: 3px;
    border-bottom-right-radius: 18px;
    border-top-left-radius: 18px;
    z-index: 5;
    flex-shrink: 0;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0px;
      border-bottom-right-radius: 18px;
      border-top-left-radius: 18px;
    }
    &::before {
      width: 62px;
      height: 58px;
      background-color: #bababa;
      z-index: 0;
    }
    &::after {
      width: 60px;
      height: 56px;
      background-color: #fff;
      z-index: 1;
    }
    img {
      position: relative;
      z-index: 6;
      width: 100%;
      height: 100%;
      border-bottom-right-radius: 15px;
      border-top-left-radius: 15px;
    }
  }
  .background-header {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  ${GameProgressBar} {
    border: 2px solid #8e21f7;
    span {
      background: linear-gradient(90deg, #33046f 0%, #47ffff 100%);
    }
  }
`;
export const HeaderCoinPoint = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  gap: 20px;
  .img-header-point {
    max-width: 200px;
    img {
      width: 100%;
    }
  }
  p {
    color: #fff;
    text-align: center;
    font-family: "Berlin Sans FB Demi";
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 96%; /* 11.52px */
    letter-spacing: -0.6px;
    text-transform: uppercase;
    position: absolute;
    text-align: center;
    width: 100%;
    left: 20px;
    top: 18px;
  }
`;
export const InfoUser = styled.div`
  width: 100%;
`;
export const UserName = styled.p`
  /* overflow: hidden; */
  color: var(--Neutral-50, #f7f7f8);
  text-overflow: ellipsis;
  font-family: "Berlin Sans FB Demi";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 12.5px */
  /* background: url(${bg_info_1}) no-repeat center / cover; */
  /* height: 25px; */
  position: relative;
  z-index: 1;
  p {
    margin-left: 5px;
    margin-top: 3px;
  }
`;
export const UserInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* background: url(${bg_info_2}) no-repeat center / cover; */
  left: -10px;
  padding: 4px 0;
  padding-right: 10px;
  padding-left: 15px;
  .level {
    position: relative;
    z-index: 1;
  }
  h1 {
    position: absolute;
    color: #fff;
    text-align: center;
    font-family: "Berlin Sans FB Demi";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 11.25px */
    right: 18px;
    top: 3px;
    z-index: 1;
  }
  .bg-infor {
    position: absolute;
    top: -22px;
    left: 5px;
    width: 135px;
    img {
      width: 100%;
    }
  }
  ${GameProgressBar} {
    width: 65%;
    height: 13px;
  }
  & > p {
    span {
      &:nth-child(2) {
        margin-left: auto;
        padding-right: 20px;
      }
    }
  }
  & > div,
  & > p {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  span {
    color: #2e363e;
    font-family: "Berlin Sans FB Demi";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 12.5px */
  }
`;
