import { useNavigate } from "react-router-dom";
import ButtonCommonV2 from "../../components/Common/Button";
import {
  BoardPageStyle,
  BoardPageWrap,
  ClickBanner,
  PredictionBanner,
  WrapContentBanner,
} from "./styled";

export default function BoardPage() {
  const navigate = useNavigate();

  return (
    <BoardPageStyle className="animate__animated animate__fadeIn">
      <BoardPageWrap>
        <ClickBanner>
          {/* <h1 className="text-status">Hot!</h1> */}
          <WrapContentBanner>
            <div className="img-title">
              <img src="/static/img-title-click.png" alt="click" />
            </div>
            <p>Don't forget to check attendance every day and keep earning</p>
            <ButtonCommonV2
              text="Play Now "
              onClick={() => {
                navigate("/clicker");
              }}
            />
          </WrapContentBanner>
        </ClickBanner>
        <PredictionBanner>
          {/* <h1 className="text-status">Soon!</h1> */}
          <WrapContentBanner>
            <div className="img-title">
              <img src="/static/img-title-predic.png" alt="predic" />
            </div>
            <p>Don't forget to check attendance every day and keep earning</p>
            <ButtonCommonV2 text="Coming Soon" />
          </WrapContentBanner>
        </PredictionBanner>
      </BoardPageWrap>
    </BoardPageStyle>
  );
}
