import { styled } from 'styled-components';

export const ButtonCommonStyle = styled.div<{
  textColor: any;
  bgColor: any;
  boxShadowColor: any;
  width: any;
}>`
  width: ${({ width }) => (width ? width : 'auto')};
  display: flex;
  padding: var(--Click, 9px) var(--Click, 20px) var(--Click, 14px)
    var(--Click, 20px);
  justify-content: center;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 9px;
  border: 1.509px solid #fff;
  background: ${({ bgColor }) => (bgColor ? bgColor : '#fdc920')};
  box-shadow: 0px -5px 0px 0px ${({ boxShadowColor }) =>
      boxShadowColor ? boxShadowColor : '#CE6000'} inset;
  span {
    color: ${({ textColor }) => (textColor ? textColor : '#ff6200')};
    text-align: center;
    font-family: 'Berlin Sans FB Demi';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 96%; /* 13.44px */
    text-transform: uppercase;
  }
`;
