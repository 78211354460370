import styled from "styled-components";

export const WalletContainer = styled.div``
export const WalletWrapper = styled.div``
export const WalletContent = styled.div``
export const WalletAddress = styled.div`
  padding: 14px 0;
  border-radius: 10.12px;
  background: #28272c;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  &.light {
    background-color: #f3f4f8;
    h1 {
      color: #28272c;
    }
  }
  h1 {
    color: #f7f7f8;
    margin-left: 14px;
  }
  span {
    padding-left: 10px;
    color: #92929e;
  }
`;
export const ButtonDisconnect = styled.div`
  border-radius: 9px;
  border: 2px solid #1000C3;
  background: linear-gradient(180deg, #7AAFFF 0%, #236EE0 100%);
  box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.25) inset;
  color: #FFF;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #3C72FF;
  font-family: "ErasBoldITC";
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  padding: 10px 10px 13px;
`