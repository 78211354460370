import styled from "styled-components";
import bgLeaderboard from "../../assets/Game/bg_leaderboard.png";

export const ContentLeaderboard = styled.div`
  padding: 85px 15px 0;
`;
export const ContentLeaderboardWrapper = styled.div``;
export const ContentLeaderboardInner = styled.div`
  position: relative;
  min-height: 593px;
`;
export const ButtonClose = styled.div`
  width: 28px;
  position: absolute;
  right: 0;
  top: 110px;
  img {
    width: 100%;
  }
`;
export const ContentTop = styled.div``;
export const TitleLeaderboard = styled.h2`
  color: #fff;
  text-align: center;
  font-family: "Berlin Sans FB Demi";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 96%; /* 15.36px */
  background: url("/images/Leaderboard/leaderboard_title.png") center / cover
    no-repeat;
  width: 243px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  span {
    padding: 12px 0 0 25px;
  }
`;
export const Contentleaderboard = styled.div`
  border-radius: 12px;
  background: #1b2036;
  box-shadow: 0px -7px 4px 0px #03050d inset, 0px 6px 0px 0px #4b5dad inset;
  padding-bottom: 7px;
  height: calc(100vh - 280px);
  height: calc(100svh - 280px);
`;
export const ContentleaderboardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  .title-left {
    display: flex;
    align-items: center;
    gap: 20px;
    p {
      color: #fff;
      text-align: center;
      font-family: "Berlin Sans FB Demi";
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.64px;
    }
  }
  .content-right {
    max-width: 50px;
    position: relative;
    top: 3px;
    img {
      width: 47px
    }
  }
`;
export const ListleaderboardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 88%;
`;
export const Listleaderboard = styled.ul`
  padding: 0 12px;
  margin-bottom: 18px;
  height: 100%;
  overflow: auto;
  li {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:nth-child(1) {
      border-radius: 8px;
      .content-position {
        &:before {
          background-color: #f87d7e;
        }
        &:after {
          box-shadow: 0px 3px 0px 0px #c82d2d inset;
          position: absolute;
          content: "";
          top: -2px;
          left: 3px;
          right: 0;
          height: 33px;
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
        }
      }
      .image-position {
        width: 49px;
      }
      .content-user {
        &:before {
          background-color: #ff6061;
        }
      }
    }
    &:nth-child(2) {
      border-radius: 8px;
      .content-position {
        &:before {
          background-color: #7dbaf8;
        }
        &:after {
          box-shadow: 0px 3px 0px 0px #2d95ff inset;
          position: absolute;
          content: "";
          top: -2px;
          left: 3px;
          right: 0;
          height: 33px;
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
        }
      }
      .image-position {
        width: 42px;
      }
      .content-user {
        &:before {
          background-color: #2691ff;
        }
      }
    }
    &:nth-child(3) {
      border-radius: 8px;
      .content-position {
        &:before {
          background-color: #7fdc8f;
        }
        &:after {
          box-shadow: 0px 3px 0px 0px #27a933 inset;
          position: absolute;
          content: "";
          top: -2px;
          left: 3px;
          right: 0;
          height: 33px;
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
        }
      }
      .image-position {
        width: 34px;
      }
      .content-user {
        &:before {
          background-color: #21d640;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    .content-position {
      width: 55%;
      display: flex;
      align-items: center;
      gap: 20px;
      &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 55%;
        bottom: 0;
        background-color: #6e8ac6;
        z-index: -1;
        border-radius: 8px 0 0 8px;
        transform: skewX(-10deg);
      }
    }
    .position-left {
      width: 50px;
      text-align: center;
    }
    .position {
      color: #1c429f;
      text-align: center;
      font-family: "Berlin Sans FB Demi";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 30px */
    }
    .name-user {
      overflow: hidden;
      color: var(--Neutral-50, #f7f7f8);
      text-overflow: ellipsis;
      font-family: "Berlin Sans FB Demi";
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 18.75px */
    }
    .image-position {
      width: 34px;
      position: relative;
      bottom: -3px;
    }
    .content-user {
      margin-left: auto;
      border-radius: 49px;
      padding: 13px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        width: 46%;
        background-color: #4e6ab0;
        box-shadow: 0px -2px 4px 0px rgba(255, 255, 255, 0.25) inset;
        transform: skew(-10deg);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        z-index: -1;
      }
      span {
        font-family: "Berlin Sans FB Demi";
        color: #fff;
        text-align: right;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 96%; /* 15.36px */
      }
    }
  }
`;

export const ImageleaderboardBottom = styled.div`
  width: 430px;
  position: fixed;
  bottom: 10px;
  z-index: 101;
  img {
    width: 100%;
  }
`;

export const MyRank = styled.div`
  border-top: 2px solid #3b488a;
  background: #37406d;
  padding: 7px 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px -7px 4px 0px #37406d inset;
  margin-top: auto;
  .my-rank-inner {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content-position {
    width: 55%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 55%;
      bottom: 0;
      background-color: #6e8ac6;
      z-index: -1;
      border-radius: 8px 0 0 8px;
      transform: skewX(-10deg);
    }
  }
  .position {
    color: #1c429f;
    text-align: center;
    font-family: "Berlin Sans FB Demi";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
  }
  .name-user {
    overflow: hidden;
    color: var(--Neutral-50, #f7f7f8);
    text-overflow: ellipsis;
    font-family: "Berlin Sans FB Demi";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 18.75px */
    width: 116px;
  }
  .content-user {
    margin-left: auto;
    border-radius: 49px;
    padding: 13px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      width: 46%;
      background-color: #4e6ab0;
      box-shadow: 0px -2px 4px 0px rgba(255, 255, 255, 0.25) inset;
      transform: skew(-10deg);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      z-index: -1;
    }
    span {
      font-family: "Berlin Sans FB Demi";
      color: #fff;
      text-align: right;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 96%; /* 15.36px */
    }
  }
`;
