import { RULE_GAME } from '../constant';

export const getValueSetting = (level: any) => {
  let pointOfLevel = 0;
  let pointToPlus = 1;
  switch (level) {
    case 0:
      pointOfLevel = RULE_GAME.EXP.lv1;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv0;
      break;
    case 1:
      pointOfLevel = RULE_GAME.EXP.lv2;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv1;
      break;
    case 2:
      pointOfLevel = RULE_GAME.EXP.lv3;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv2;
      break;
    case 3:
      pointOfLevel = RULE_GAME.EXP.lv4;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv3;
      break;
    case 4:
      pointOfLevel = RULE_GAME.EXP.lv5;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv4;
      break;
    case 5:
      pointOfLevel = RULE_GAME.EXP.lv6;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv5;
      break;
    case 6:
      pointOfLevel = RULE_GAME.EXP.lv7;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv6;
      break;
    case 7:
      pointOfLevel = RULE_GAME.EXP.lv8;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv7;
      break;
    case 8:
      pointOfLevel = RULE_GAME.EXP.lv9;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv8;
      break;
    case 9:
      pointOfLevel = RULE_GAME.EXP.lv10;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv9;
      break;
    case 10:
      pointOfLevel = RULE_GAME.EXP.lv10;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv10;
      break;
    default:
      pointOfLevel = RULE_GAME.EXP.lv1;
      pointToPlus = RULE_GAME.POINT_BY_LEVEL.lv0;
      break;
  }
  return {
    pointOfLevel,
    pointToPlus,
  };
};
