import styled from 'styled-components';

export const DashboardContainer = styled.div`
  height: 100vh;
  height: 100svh;
  position: relative;
  overflow: hidden;
`;

export const AudioStyle = styled.div`
  position: absolute;
  z-index: 999;
  top: 85px;
  right: 10px;
  max-width: 40px;
  .img-audio {
    max-width: 40px;
    img {
      width: 100%;
    }
  }
`;

export const DashboardMain = styled.div`
  max-width: 450px;
  margin: 0 auto;
  /* padding: 0 15px; */
  padding-top: 80px;
  height: 100vh;
  height: 100svh;
`;

export const DashboardContainerDesKtop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .img-qr-game {
    max-width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
      margin-top: -130px;
    }
  }
  h1 {
    position: absolute;
    top: 65%;
    color: #fff;
    text-align: center;
    color: var(--Neutral-50, #f7f7f8);
    -webkit-text-stroke-width: 0.9333948493003845;
    -webkit-text-stroke-color: #737373;
    font-family: 'ErasBoldITC';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 21.25px */
    text-transform: uppercase;
  }
`;
