import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  AudioStyle,
  DashboardContainer,
  DashboardContainerDesKtop,
} from './styled';
import GameFooter from '../../components/Footer';
import GameHeaderV2 from '../../components/Header/indexv2';
import { useSelector } from 'react-redux';
import LoadingV2 from '../../page/LoadingV2';
import { useEffect, useState } from 'react';
import { isMobile } from '../../utils/checkingMobile';

const LayoutDashboard = () => {
  const { infoUser } = useSelector((state: any) => state.point);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (infoUser && location.pathname === '/') {
      navigate('/games');
    }
  }, [infoUser]);

  useEffect(() => {
    const overflow = 100;
    document.body.style.overflowY = 'hidden';
    document.body.style.marginTop = `${overflow}px`;
    document.body.style.height = window.innerHeight + overflow + 'px';
    document.body.style.paddingBottom = `${overflow}px`;
    window.scrollTo(0, overflow);
  }, []);

  return (
    <DashboardContainer>
      {isMobile() ? (
        <>
          {infoUser ? (
            <>
              <GameHeaderV2 />
              {/* <AudioStyle onClickCapture={toggleAudio}>
                <div className="img-audio">
                  {isPlaying ? (
                    <img src="/static/icon-play.png" alt="play" />
                  ) : (
                    <img src="/static/icon-pause.png" alt="pause" />
                  )}
                </div>
              </AudioStyle> */}
              <Outlet />
              <GameFooter />
            </>
          ) : (
            <LoadingV2 />
          )}
        </>
      ) : (
        <DashboardContainerDesKtop>
          <h1>Play on your phone, please!</h1>
          <div className="img-qr-game">
            <img src="/static/qr-game.jpg" alt="game" />
          </div>
        </DashboardContainerDesKtop>
      )}
    </DashboardContainer>
  );
};
export default LayoutDashboard;
