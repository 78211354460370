import styled from 'styled-components';
import bg from '../../assets/Game/game_bg.jpg';
import layer_intro from '../../assets/Game/layer_info.png';
import coin_intro_decor from '../../assets/Game/coin_intro_decor.png';
import game_block from '../../assets/Game/game_block_bg.png';
import icnBgGuide from '../../assets/Game/icn_bg_guide.png';

export const GameContainer = styled.div<{ State: number }>`
  position: relative;
  width: 100%;
  .img-bg-click {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 50px;
    z-index: -5;
    img {
      width: 100%;
      height: 100vh;
      height: 100svh;
    }
  }
`;

export const RebootEnergy = styled.div`
  position: absolute;
  top: 100px;
  left: 5px;
  .img-reboot {
    max-width: 70px;
    margin-left: 8px;
    img {
      width: 100%;
    }
  }
`;

export const RebootEnergyWrap = styled.div<{ isCountDown: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 99;
  animation: ${({ isCountDown }) =>
    isCountDown ? '' : 'ZoomInOut 1s infinite linear'};
  @keyframes ZoomInOut {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.9);
    }
  }
`;

export const ButtonReboot = styled.div`
  display: inline-flex;
  padding: 7px 7.333px 6px 7.333px;
  justify-content: center;
  align-items: center;
  border-radius: 5.333px;
  border: 2px solid #fff;
  background: #ff9f26;
  margin-top: -22px;
  span {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0.878px 0px #482200;
    -webkit-text-stroke-width: 1.0246914625167847;
    -webkit-text-stroke-color: #703500;
    font-family: 'Berlin Sans FB Demi';
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 78%; /* 8.554px */
  }
`;

export const ButtonCountDown = styled.div<{ process: any }>`
  border-radius: 12.82px;
  border: 1.11px solid #f76121;
  background: #282828;
  width: 70px;
  height: 20px;
  flex-shrink: 0;
  margin-top: -22px;
  position: relative;
  .process {
    display: flex;
    align-items: center;
    width: ${({ process }) => (process ? `${process}%` : '100%')};
    height: 100%;
    border-radius: 25.64px;
    background: linear-gradient(90deg, #6f0404 0%, #ffbc47 100%);
  }
  p {
    color: #fff;
    text-align: center;
    font-family: 'Coda Caption';
    font-size: 9.222px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%; /* 11.528px */
    width: 100%;
    position: absolute;
    top: 4px;
  }
`;

export const GameWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
`;

export const BannerPoint = styled.div`
  position: relative;
  width: 280px;
  height: 50px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  .image-banner-point {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    img {
      width: 230px;
    }
  }
  p {
    color: #fff;
    text-align: center;
    text-shadow: 0px 3px 0px #ce6000;
    font-family: 'Berlin Sans FB Demi';
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 96%; /* 23.04px */
    letter-spacing: -1.44px;
    text-transform: uppercase;
    margin-left: 30px;
    margin-top: -5px;
  }
`;

export const BannerEnergy = styled.div`
  width: 150px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -8px;
  position: relative;
  z-index: 1;
  .image-banner-enrgy {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -3px;
    z-index: -1;
    width: 170px;
    img {
      width: 100%;
    }
  }
  .img-buy-energy {
    max-width: 16px;
    mix-blend-mode: plus-lighter;
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 99;
    img {
      width: 100%;
    }
  }
  p {
    color: #fff;
    text-align: center;
    font-family: 'Berlin Sans FB Demi';
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%; /* 12.5px */
  }
  span {
    color: #b050ff;
    font-family: 'Berlin Sans FB Demi';
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%;
  }
`;

export const GameIntro = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 629px;
    height: 896px;
    margin-left: -315px;
    background: url(${layer_intro}) no-repeat center / 100% auto;
  }
`;
export const GameIntroImage = styled.div`
  position: relative;
  width: 309px;
  height: 315px;
  margin: 0 auto;
  img {
    width: 100%;
  }
  &::before {
    content: '';
    position: absolute;
    margin-left: -180px;
    left: 50%;
    top: -40px;
    background: url(${coin_intro_decor}) no-repeat center / 100% auto;
    width: 388px;
    height: 314px;
  }
`;
export const GameIntroText = styled.div`
  position: relative;
  padding: 0 20px;
  & > h2 {
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: ErasBoldITC;
    margin-bottom: 15px;
  }
  & > p {
    font-size: 15px;
    text-align: center;
    color: #fff;
    font-family: iCiel;
    line-height: 20px;
    span {
      color: #1eb0ff;
    }
  }
`;
export const GameHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 70px;
  z-index: 10;
  & > figure {
    position: relative;
    width: 42px;
    height: 43px;
    img {
      width: 100%;
    }
  }
  & > p {
    font-size: 20px;
    font-family: ErasBoldITC;
    font-size: 36px;
    color: #fff;
    text-transform: lowercase;
  }
`;
export const CoinBaseImage = styled.img<{ count?: any }>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -21px;
  margin-top: -22px;
  width: 42px;
  height: 43px;
  opacity: 0;
  animation: flowCoinBase 1s cubic-bezier(0.23, 1, 0.32, 1);
  @keyframes flowCoinBase {
    0% {
      transform: translateX(0) translateY(300%);
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateX(0) translateY(0);
    }
  }
`;

export const PointAppear = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  .img-count {
    pointer-events: none;
    max-width: 40px;
    animation: flowPoint 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    h1 {
      color: #fff;
      text-align: center;
      text-shadow: 0px 3.593px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #ff6b04;
      font-family: 'Luckiest Guy', cursive;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 62.884px */
    }
  }
  &.is-click {
    pointer-events: auto;
  }
  @keyframes flowPoint {
    0% {
      transform: -100px;
    }
    10% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    70% {
      opacity: 0.4;
    }
    100% {
      transform: translateY(-300%);
    }
  }
`;

export const GameCoin = styled.div`
  position: relative;
  z-index: 10;
  width: 300px;
  margin: 0 auto;
`;

export const MonkeyWrap = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 320px);
  height: calc(100svh - 320px);
  .img-monkey {
    margin: 0 auto;
    position: absolute;
    width: 100%;
    bottom: 13vh;
    bottom: 13svh;
    left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    img {
      width: 90%;
      margin: 0 auto;
    }
  }
`;

export const GameProgressText = styled.div`
  font-family: ErasBoldITC;
  font-size: 15px;
  text-transform: lowercase;
  color: #fff;
  position: absolute;
  z-index: 1;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  font-size: 17px;
  font-style: normal;
  line-height: 1;
  opacity: 0;
  transition: opacity 0.26s ease;
  right: 60px;
  top: 0;
`;
export const GameProgress = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row-reverse;
  z-index: 10;
  width: 0;
  opacity: 0;
  transition: width 0.26s ease;
  .image-energy {
    position: absolute;
    top: -40px;
    left: 20px;
    z-index: 1;
    width: 100px;
    height: 100px;
    img {
      width: 100px;
      height: 100px;
      pointer-events: none;
    }
  }
  .image-point {
    position: absolute;
    right: 30px;
    top: -33px;
    z-index: 1;
    width: 85px;
    height: 85px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &.is-show {
    width: 100%;
    opacity: 1;
    &:before {
      opacity: 1;
    }
    ${GameProgressText} {
      opacity: 1;
      /* right: 30px; */
      left: unset;
      z-index: 3;
    }
  }
`;

export const GameProgressBar = styled.div<{ Progress: number }>`
  width: 60%;
  margin: 0 auto;
  height: 13px;
  border: 2px solid #f7a121;
  background: #282828;
  border-radius: 30px;
  overflow: hidden;
  span {
    display: block;
    height: 13px;
    width: ${({ Progress }) => (Progress > 0 ? Progress : 0)}%;
    background: linear-gradient(90deg, #6f3e04 0%, #f7a121 100%);
    border-radius: ${({ Progress }) => (Progress === 100 ? '0' : '20px')};
    transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;
export const GameTools = styled.div`
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 15px 0;
  gap: 10px;
`;
export const GameBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 40%;
  padding: 7px 0 5px;
  margin-top: -17px;
  margin-left: auto;
  /* background: url(${game_block}) no-repeat center / 100% 100%; */
  position: relative;
  z-index: 1;
  &:before {
    border-radius: 10px;
    background-color: #232c4c;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: skewX(-8deg);
  }
  & > div {
    width: 100%;
    text-align: center;
    & > p {
      color: #fff;
      text-align: center;
      text-shadow: 0px 1.953px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #ff5e03;
      font-family: ErasBoldITC;
      font-size: 14px;
      line-height: 1;
    }
  }
  &:nth-child(1) {
    ${GameProgressBar} {
      border-color: #34f497;
      span {
        background: linear-gradient(90deg, #282828 0%, #0ff586 100%);
      }
    }
  }
  &:nth-child(2) {
    ${GameProgressBar} {
      border-color: #db4c4a;
      span {
        background: linear-gradient(90deg, #db4843 0%, #ffff69 100%);
      }
    }
  }
  figure {
    position: relative;
    top: 0px;
    width: 14px;
    height: 17px;
    flex-shrink: 0;
    /* left: 23px; */
    img {
      position: relative;
      z-index: 10;
      width: 100%;
    }
  }
  ${GameProgressBar} {
    width: 100%;
    z-index: 10;
  }
`;

export const BlockTime = styled.div`
  position: relative;
  .time-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    -webkit-text-stroke-width: 0.9333948493003845;
    -webkit-text-stroke-color: #6590ff;
    font-family: ErasBoldITC;
    font-size: 11.667px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    right: 14px;
    bottom: 9px;
  }
`;
export const LeaderBoard = styled.div`
  text-align: center;
  position: relative;
  z-index: 1;
  width: 62px;
  margin-left: auto;
  margin-top: 15px;
  margin-right: 15px;
  span {
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ff5b02;
    font-family: ErasBoldITC;
    font-size: 12px;
  }
`;
export const BlockGuide = styled.div`
  color: #fff;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    max-width: 87px;
    width: 100%;
  }
  .guide-inner {
    max-width: 304px;
    width: 100%;
    background: url(${icnBgGuide}) center / 304px auto no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 17px 10px 30px;
    p {
      color: var(--Blue--Pri-700, #007af5);
      font-family: Franklin;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 18.75px */
    }
    span {
      color: var(--trionn-com-woodsmoke, #121315);
      font-family: ErasBoldITC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      display: inline-block;
    }
    button {
      border-radius: 9px;
      border: 2px solid #1000c3;
      background: linear-gradient(180deg, #7aafff 0%, #236ee0 100%);
      box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.25) inset;
      padding: 9px 30px;
      color: #fff;
      text-align: center;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #3c72ff;
      font-family: ErasBoldITC;
      font-size: 14px;
      font-style: normal;
    }
  }
`;
