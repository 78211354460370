export const convertBigNumberToText = (num: number) => {
  if (!num) return;
  if (num >= 1e6) {
    return (num / 1e6).toFixed(2).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(2).replace(/\.0$/, '') + 'K';
  }
  return num.toString();
};
