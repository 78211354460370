import { styled } from "styled-components";

export const GameContainer = styled.div`
  position: relative;
  padding: 100px 15px 0;
  width: 100%;
  box-sizing: border-box;
  .animate-rotate {
    animation: rotate 2s infinite linear;
    @keyframes rotate { 
      100% { 
          -webkit-transform: rotate(360deg); 
          transform:rotate(360deg); 
      } 
    }
  }
`;

export const ButtonTabPartner = styled.div`
  max-width: 50%;
  width: 100%;
  border-radius: 9px;
  border: 1.509px solid #fff;
  background: linear-gradient(180deg, #acacac 0%, #505050 100%);
  box-shadow: 0px -5px 0px 0px #434343 inset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 40px;
  p {
    color: #fff;
    text-align: center;
    font-family: ErasBoldITC;
    font-size: 16px;
    line-height: 1;
  }
  .block-time {
    color: #b6b6b6;
    text-align: center;
    text-shadow: 0px 1.509px 0px #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 15px */
  }
`;

export const GameHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const DailyTask = styled.div``;

export const MysteryTask = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: flex-end;
  margin: 0 auto;
  margin-bottom: 5px;
  margin-top: 20px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 12.425px 0px rgba(0, 0, 0, 0.47); */
`;

export const MysteryTaskItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const MysteryTaskTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin-bottom: 3px;
  .img-random {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    max-width: 14px;
    img {
      width: 100%;
    }
  }
  h4 {
    color: #fff;
    font-family: "Berlin Sans FB Demi";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 96%; /* 13.44px */
  }
`;

export const MysteryTaskRandom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  border-radius: 6px;
  width: 100%;
  padding: 5px 10px;
  border: 1.69px solid #fff;
  background: #000;
  box-sizing: border-box;
  margin-bottom: 3px;
  position: relative;
  .icon-all-coin {
    max-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .img-check {
    max-width: 16px;
    position: absolute;
    top: -5px;
    right: 0;
    img {
      width: 100%;
    }
  }
`;

export const MysteryTaskRandomTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  p {
    color: #dbdbdb;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 12px */
    letter-spacing: -0.4px;
    text-transform: capitalize;
  }
  h4 {
    color: #fff;
    font-family: "Eras Bold ITC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.48px;
  }
  .img-k {
    max-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    img {
      width: 100%;
      border-radius: 6px;
    }
  }
`;

export const MysteryTaskRandomPoint = styled.div`
  position: relative;
  h2 {
    color: #fd0;
    text-align: center;
    text-shadow: 0px 1.557px 0px #482200;
    -webkit-text-stroke-width: 0.3114880621433258;
    -webkit-text-stroke-color: #482200;
    font-family: "Eras Bold ITC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 89%; /* 11.406px */
    letter-spacing: -0.769px;
    margin-top: -10px;
    margin-left: -5px;
  }
  .img-check {
    max-width: 16px;
    img {
      width: 100%;
    }
  }
`;

export const IconDailyBonus = styled.div`
  .img-daily {
    max-width: 90px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    img {
      width: 100%;
      height: 100%;
      margin-bottom: -10px;
    }
  }
`;

export const ListTask = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 3px;
  padding: 10px 5px;
  max-height: calc(100vh - 400px);
  max-height: calc(100svh - 400px);
  overflow: auto;
  height: 50vh;
  height: 50svh;
  margin-top: -5px;

  .task-un-complete {
    border-radius: 6px;
    background: rgba(159, 56, 0, 0.39);
    backdrop-filter: blur(7px);
    border: 2px solid rgba(159, 56, 0, 0.39);
    position: relative;
  }
  .task-complete {
    position: relative;
    border-radius: 6px;
    border: 2px solid #ff9e49;
    background: rgba(255, 89, 0, 0.39);
    backdrop-filter: blur(7px);
  }
  .img-check {
    position: absolute;
    right: -5px;
    top: -8px;
    max-width: 14px;
    img {
      width: 100%;
    }
  }
  .all-task-claim {
    border-radius: 12px;
    border: 2px solid #d94500;
    background: #ffd15b;
    width: 90%;
    margin: 0 auto;
    .content-left {
      h4 {
        color: #d94500;
        font-family: 'ErasBoldITC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 17.5px */
        margin: 0;
        -webkit-text-stroke-width: 0;
        -webkit-text-stroke-color: none;
        margin-left: 4px;
        text-shadow: none;
      }
      h3 {
        color: var(--Neutral-50, #f7f7f8);
        -webkit-text-stroke-width: 0.5;
        -webkit-text-stroke-color: #fd7f01;
        font-family: 'ErasBoldITC';
        font-size: 18px;
        margin-left: 4px;
        line-height: 20px;
        text-shadow: none;
      }
      .title {
        p {
          color: #d94500;
        }
      }
    }
    .content-right {
      background: url('/static/bg-earn.png') center / cover no-repeat;
      .point {
        color: #fff;
        text-align: center;
        text-shadow: none;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fd7f01;
        font-family: 'ErasBoldITC';
        font-size: 14px;
        line-height: 1;
        margin-bottom: 3px;
      }
      button {
        display: block;
        color: #fff;
        text-align: center;
        text-shadow: 0px 1.509px 0px #d94500;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fd7f01;
        font-family: 'ErasBoldITC';
        font-size: 13px;
        line-height: 1;
        border-radius: 6.79px;
        border: 1.509px solid #fff;
        background: linear-gradient(180deg, #ffd902 0%, #ff5602 100%);
        box-shadow: 0px -5px 0px 0px #fd3801 inset;
        padding: 5px 10px;
        cursor: pointer;
      }
    }
  }
`;
