import { ToastMessType } from '../../redux/features/pointSlice';
import { ToastContainer, ToastWrap } from './style';

export default function ToastMessage({ data }: any) {
  return (
    <ToastContainer className="animate__animated animate__backInRight">
      <ToastWrap>
        <div className="img-status">
          {data.status ? (
            <img src="/static/banner-success.png" alt="err" />
          ) : (
            <img src="/static/banner-err.png" alt="err" />
          )}
        </div>
        <p>{data.message}</p>
      </ToastWrap>
    </ToastContainer>
  );
}
