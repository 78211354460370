import styled from 'styled-components';
import bgTitle from '../../../assets/Game/Assets/Shop/bg_title.png';
import bgTitle02 from '../../../assets/Game/Assets/Shop/bg_title_02.png';
import bgTime from '../../../assets/Game/Assets/Shop/bg_time.png';
import bgTime02 from '../../../assets/Game/Assets/Shop/bg_time_02.png';
import bgCommon from '../../../assets/Game/Assets/Shop/img_daily_common.png';
import bgSpecial from '../../../assets/Game/Assets/Shop/bg_special.png';

export const AssetsShopContent = styled.div`
  padding-top: 130px;
  .img-header-shop {
    max-width: 100%;
    position: fixed;
    top: 60px;
    z-index: 99;
    img {
      width: 100%;
    }
  }
`;

export const AssetsWrapTab = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
`
  
export const AssetsShopWrapper = styled.div`
  padding: 20px 15px;
`;
export const AssetsShopBlock = styled.div`
  margin-bottom: 10px;
  .content-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 39px;
    background: url(${bgTitle}) center / contain no-repeat;
    margin-bottom: 0;
    .text-left {
      color: #fff;
      text-align: center;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.83);
      font-family: 'Berlin Sans FB Demi';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 24px */
      letter-spacing: -0.8px;
    }
  }
  .content-buy {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 25px;
    max-width: 200px;
    h2 {
      color: #fff;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.83);
      font-family: 'Berlin Sans FB Demi';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 24px */
      letter-spacing: -0.8px;
    }
    p {
      margin-top: 10px;
      color: #fff;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 12.5px */
      letter-spacing: -0.4px;
      text-align: left;
    }
  }
  .block-time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: url(${bgTime}) center / contain no-repeat;
    padding: 15px 11px 12px 49px;
    span {
      color: #fff;
      font-family: Berlin Sans FB Demi;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 96%; /* 9.07px */
      letter-spacing: -0.472px;
      text-transform: lowercase;
    }
  }
  .block-premium {
    max-width: 385px;
    margin: 0 auto;
    background: url('/static/bg-item-buy-energy.png') center / cover no-repeat;
    min-height: 158px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    padding-top: 20px;
    .img-hot {
      position: absolute;
      top: 0%;
      left: 0;
      width: 62px;
      img {
        width: 100%;
      }
    }
    .icon-coin-big {
      position: absolute;
      bottom: -9px;
      right: 0;
      width: 66px;
      z-index: 1;
      pointer-events: none;
      img {
        width: 100%;
      }
    }
    h1 {
      position: absolute;
      z-index: 2;
      transform: rotate(-45deg);
      top: 10px;
      left: 5px;
      overflow: hidden;
      color: var(--Neutral-50, #f7f7f8);
      text-overflow: ellipsis;
      font-family: 'Berlin Sans FB Demi';
      font-size: 14.258px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 17.823px */
    }
    .content-inner {
      position: absolute;
      top: 45%;
      transform: translateY(-50%);
      right: 100px;
      text-align: left;
    }
    .title-premium {
      color: #fff;
      text-align: center;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.83);
      font-family: 'Berlin Sans FB Demi';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 24px */
      letter-spacing: -0.8px;
    }
    .total-coin {
      color: #fff;
      text-shadow: 0px 1.045px 0px rgba(0, 0, 0, 0.83);
      font-size: 14.633px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 17.56px */
      letter-spacing: -0.585px;
      text-transform: uppercase;
      margin-bottom: -20px;
    }
    .total-old {
      color: #ffd900;
      text-shadow: 0px 0.76px 0px rgba(0, 0, 0, 0.83);
      font-size: 10.644px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 12.773px */
      letter-spacing: -0.426px;
      text-transform: uppercase;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #ff3232;
        transform: translateY(-50%) rotate(6deg);
      }
    }
    .button-buy {
      outline: none;
      border-radius: 9px;
      border: 1.509px solid #fff;
      background: #fdc920;
      box-shadow: 0px -5px 0px 0px #ce6000 inset;
      color: #ff6200;
      text-align: center;
      font-size: 15.708px;
      font-style: normal;
      font-weight: 800;
      line-height: 96%; /* 15.08px */
      letter-spacing: -0.785px;
      text-transform: lowercase;
      width: 100%;
      position: absolute;
      bottom: 22px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 92.8%;
      min-height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 4px;
      img {
        width: 29px;
      }
    }
  }
  &.is-daily {
    .content-title {
      background-image: url(${bgTitle02});
    }
    .block-time {
      background-image: url(${bgTime02});
    }
  }
`;
export const ListDaily = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px;
  max-width: 385px;
  margin: 0 auto;
`;
export const ListDailyItem = styled.li`
  max-width: calc(100% / 2 - 2px);
  width: 100%;
  background: url(${bgCommon}) center / cover no-repeat;
  min-height: 148px;
  position: relative;
  padding: 15px 0;
  border-radius: 12px;
  overflow: hidden;
  img {
    width: 100%;
  }
  .title-pack {
    color: #fff;
    text-align: center;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.83);
    font-family: 'Berlin Sans FB Demi';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
    letter-spacing: -0.8px;
  }
  @media screen and (max-width: 412px) {
    max-width: 170px;
    min-height: 130px;
    margin-bottom: 15px;
  }
`;
export const ListDailItemBest = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 62px;
  img {
    width: 100%;
  }
`;
export const ListDailIconCoin = styled.div`
  position: absolute;
  right: 0;
  bottom: -9px;
  width: 59px;
  z-index: 1;
  pointer-events: none;
  img {
    width: 100%;
  }
`;
export const ListDailBlockCoin = styled.div`
  line-height: 1;
  position: absolute;
  right: 23px;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  .text-receive {
    color: #fff;
    text-shadow: 0px 1.045px 0px rgba(0, 0, 0, 0.83);
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 17.56px */
    letter-spacing: -0.585px;
    text-transform: uppercase;
    margin-bottom: -20px;
  }
  .text-receive-old {
    color: #ffd900;
    text-shadow: 0px 0.76px 0px rgba(0, 0, 0, 0.83);
    font-size: 11px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 12.773px */
    letter-spacing: -0.426px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #ff3232;
      transform: translateY(-50%) rotate(6deg);
    }
  }
`;
export const ListDailBuy = styled.button`
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
  max-width: 88%;
  width: 100%;
  margin: 0 auto;
  border-radius: 9px;
  border: 1.509px solid #fff;
  background: #fdc920;
  box-shadow: 0px -5px 0px 0px #ce6000 inset;
  color: #ff6200;
  text-align: center;
  font-size: 15.708px;
  font-style: normal;
  font-weight: 800;
  line-height: 96%; /* 15.08px */
  letter-spacing: -0.785px;
  text-transform: uppercase;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 29px;
    position: relative;
    top: -1px;
  }
`;
