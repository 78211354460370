import React, { useContext, useState } from 'react';
import {
  AssetsShopBlock,
  AssetsShopContent,
  AssetsShopWrapper,
  ListDaily,
} from './styled';
import imgDaily01 from '../../../assets/Game/Assets/Shop/img_daily_01.png';
import imgDaily02 from '../../../assets/Game/Assets/Shop/img_daily_02.png';
import imgDaily03 from '../../../assets/Game/Assets/Shop/img_daily_03.png';
import imgDaily04 from '../../../assets/Game/Assets/Shop/img_daily_04.png';
import packSmall01 from '../../../assets/Game/Assets/Shop/pack_small_01.png';
import packSmall02 from '../../../assets/Game/Assets/Shop/pack_small_02.png';
import packSmall03 from '../../../assets/Game/Assets/Shop/pack_small_03.png';
import packSmall04 from '../../../assets/Game/Assets/Shop/pack_small_04.png';
import icnCoinBig from '../../../assets/Game/Assets/Shop/icn_coin_big.png';
import icnCoin02 from '../../../assets/Game/Assets/Shop/icn_coin_02.png';
import ItemProduct from '../ItemProduct';
import ModalProduct from '../Modal';
import ModalBuyEnergy from '../Modal/modalBuyEnergy';
import { useSelector } from 'react-redux';
import { convertFormatNumber } from '../../../utils/convertFormatNumber';
import { KIBBLE_API } from '../../../services/api';
import { useDispatch } from '../../../redux/store';
import {
  getDataInfoUser,
  getListPackageEnergy,
  getToastMess,
} from '../../../redux/features/pointSlice';
import { useInitData } from '@tma.js/sdk-react';

const AssetsShopV2 = ({ setOverlayPremium }: any) => {
  const [showModalProduct, setShowModalProduct] = useState(false);
  const [isBuyEnergy, setIsBuyEnergy] = useState(false);

  const dispatch = useDispatch();
  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;

  const { listPackageEnergy } = useSelector((state: any) => state.point);

  const handleOverlayPremium = () => {
    setOverlayPremium(true);
  };

  const handleBuyEnergy = async () => {
    try {
      await KIBBLE_API.buyEnergy();
      dispatch(getDataInfoUser(user));
      dispatch(
        getToastMess({
          status: true,
          message: `Got +500 energy`,
        })
      );
      setIsBuyEnergy(false);
      setOverlayPremium(false);
      dispatch(getListPackageEnergy());
    } catch (error) {
      console.log('====================================');
      console.log('handleBuyEnergy err', error);
      console.log('====================================');
      dispatch(
        getToastMess({
          status: false,
          message: `Not enought point`,
        })
      );
      setIsBuyEnergy(false);
      setOverlayPremium(false);
    }
  };

  return (
    <AssetsShopContent>
      {isBuyEnergy && (
        <ModalBuyEnergy
          level={
            listPackageEnergy[0]?.level && listPackageEnergy[0]?.level < 10
              ? `0${listPackageEnergy[0]?.level}`
              : listPackageEnergy[0]?.level
          }
          onClose={() => {
            setIsBuyEnergy(false);
            setOverlayPremium(false);
          }}
          onSubmit={handleBuyEnergy}
          point="500"
          price={convertFormatNumber(
            Number(listPackageEnergy[0]?.point)
          )?.replaceAll('.00', '')}
        />
      )}
      <div className="img-header-shop">
        <img src="/static/img-header-shop.png" alt="shop" />
      </div>
      <AssetsShopWrapper>
        <AssetsShopBlock>
          <div className="content-title">
            <p className="text-left">Energy Shop</p>
          </div>
          {listPremium?.map((item: any, index: number) => (
            <>
              <div className="block-premium" key={index}>
                <h1>
                  Lv.
                  {listPackageEnergy[0]?.level &&
                  listPackageEnergy[0]?.level < 10
                    ? `0${listPackageEnergy[0]?.level}`
                    : listPackageEnergy[0]?.level}
                </h1>
                <div className="img-hot">
                  <img src={'/static/img-red-corner.png'} alt="corner" />
                </div>
                <div className="content-buy">
                  <h2>Energy Package</h2>
                  <p>
                    Gain +500 energy points to boost your earning potential. Buy
                    Now!
                  </p>
                </div>
                <div className="icon-coin-big">
                  <img src={icnCoinBig} alt="Coin" />
                </div>
                {listPackageEnergy[0]?.point && (
                  <button
                    className="button-buy"
                    onClick={() => {
                      setIsBuyEnergy(true);
                      handleOverlayPremium();
                    }}
                  >
                    <img src={icnCoin02} alt="Coin" />
                    {convertFormatNumber(
                      Number(listPackageEnergy[0]?.point)
                    )?.replaceAll('.00', '')}
                  </button>
                )}
              </div>
              {showModalProduct && <ModalProduct item={item} index={index} />}
            </>
          ))}
        </AssetsShopBlock>
        <AssetsShopBlock className="is-daily">
          <div className="content-title">
            <p className="text-left">$KIB Marketplace</p>
          </div>
          <ListDaily>
            {listDaily?.map((item: any, index: number) => (
              <ItemProduct
                item={item}
                index={index}
                setOverlayPremium={setOverlayPremium}
              />
            ))}
          </ListDaily>
        </AssetsShopBlock>
      </AssetsShopWrapper>
    </AssetsShopContent>
  );
};

const listPremium = [
  {
    id: 'premium',
    img: imgDaily01,
    price: '300,000',
    priceModal: 300000,
    receive: 5000,
    isHot: true,
    textReceiveNew: 'x10,000',
    textReceiveOld: 'x8,000',
    imgSmall: packSmall01,
  },
];

const listDaily = [
  {
    id: 1,
    img: imgDaily01,
    price: 'TBA',
    priceModal: 299000,
    receive: 5000,
    isBest: true,
    textReceiveNew: 'x5,000',
    textReceiveOld: 'x4,000',
    imgSmall: packSmall01,
  },
  {
    id: 2,
    img: imgDaily02,
    price: 'TBA',
    priceModal: 299000,
    receive: 5000,
    isBest: true,
    textReceiveNew: 'x12,000',
    textReceiveOld: 'x11,000',
    imgSmall: packSmall02,
  },
  {
    id: 3,
    img: imgDaily03,
    price: 'TBA',
    priceModal: 299000,
    receive: 5000,
    textReceiveNew: 'x12,000',
    textReceiveOld: 'x11,000',
    imgSmall: packSmall03,
  },
  {
    id: 4,
    img: imgDaily04,
    price: 'TBA',
    priceModal: 299000,
    receive: 5000,
    textReceiveNew: 'x12,000',
    textReceiveOld: 'x11,000',
    imgSmall: packSmall04,
  },
];

export default AssetsShopV2;
