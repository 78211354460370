import { ModalContainer } from './modal';
import {
  ButtonBuy,
  ModalBuyEnergyContainer,
  ModalBuyEnergyContent,
} from './styleModalBuyEnergy';

type ModalBuyEnergyType = {
  onClose: () => void;
  point: string;
  level: string;
  onSubmit?: () => void;
  price: any;
};

export default function ModalBuyEnergy({
  onClose,
  onSubmit,
  point,
  level,
  price,
}: ModalBuyEnergyType) {
  return (
    <ModalContainer>
      <ModalBuyEnergyContainer>
        <h3>Lv.{level}</h3>
        <div className="img-close" onClick={onClose}>
          <img src="/static/img-close.png" alt="close" />
        </div>
        <ModalBuyEnergyContent>
          <h1>+{point}</h1>
          <h2>energy</h2>
          <p>
            **At your level ({level}) you can expand {point} energy limit.
          </p>
          <ButtonBuy onClick={onSubmit}>
            <div className="img-coin">
              <img src="/static/icon-coin.png" alt="coin" />
            </div>
            <span>{price}</span>
          </ButtonBuy>
        </ModalBuyEnergyContent>
        <div className="img-bottom-right">
          <img src="/static/img-bottom-right.png" alt="right" />
        </div>
      </ModalBuyEnergyContainer>
    </ModalContainer>
  );
}
