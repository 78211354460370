import { GameFooterWrapper, GameNav } from "./styledv2";
import nav_1 from "../../assets/Game/nav_1.png";
import nav_2 from "../../assets/Game/nav_2.png";
import nav_3 from "../../assets/Game/nav_3.png";
import nav_4 from "../../assets/Game/nav_4.png";
import nav_5 from "../../assets/Game/nav_5.png";
import { NavLink } from "react-router-dom";

const GameFooter = () => {

  return (
    <GameFooterWrapper>
      <GameNav>
        {navData.map((item, index) => {
          return (
            <li key={index}>
              <NavLink to={item.to}>
                <figure>
                  <img src={item.icon} alt="icon" />
                </figure>
                <p>{item.text}</p>
              </NavLink>
            </li>
          );
        })}
      </GameNav>
    </GameFooterWrapper>
  );
};

const navData = [
  {
    id: 1,
    icon: nav_1,
    text: "",
    to: "/earn",
  },
  {
    id: 2,
    icon: nav_5,
    text: "",
    to: "/leaderboard",
  },
  {
    id: 3,
    icon: nav_2,
    text: "",
    to: "/games",
  },
  {
    id: 4,
    icon: nav_3,
    text: "",
    to: "/assets",
  },
  {
    id: 5,
    icon: nav_4,
    text: "",
    to: "/friends",
  },
];

export default GameFooter;
