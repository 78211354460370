import styled from "styled-components";
import bg01 from "../../assets/Game/friends/bg_list_01.png";
import bg02 from "../../assets/Game/friends/bg_list_02.png";
import logoUser from "../../assets/Game/friends/logo_user.png";
import {
  Contentleaderboard,
  ContentleaderboardTitle,
  MyRank,
} from "../Leaderboard/styledV2";
import { Listleaderboard } from "../Leaderboard/styled";

export const FriendContainer = styled.div``;
export const FriendWrapper = styled.div`
  padding: 100px 15px 0;
`;
export const FriendListTop = styled.ul`
  li {
    border-radius: 12px;
    overflow: hidden;
    padding: 15px 29px 15px 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: center / cover no-repeat;
    &:nth-child(1) {
      background-image: url("/images/Friends/bg_invite_01.png");
    }
    &:nth-child(2) {
      background-image: url("/images/Friends/bg_invite_02.png");
      .content-left {
        p {
          color: var(--trionn-com-woodsmoke, #121315);
        }
      }
    }
    .content-left {
      max-width: 60%;
    }
    .icon-decor {
      position: absolute;
      left: 0;
      top: 0;
      width: 62px;
      img {
        width: 100%;
      }
    }
    h3 {
      color: var(--Neutral-50, #f7f7f8);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #3b72ff;
      font-family: ErasBoldITC;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 3px;
    }
    p {
      color: var(--trionn-com-nero, #fff);
      font-family: "Berlin Sans FB Demi";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 12px */
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .button-claim {
    outline: none;
    color: #ff6200;
    text-align: center;
    font-family: "Berlin Sans FB Demi";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 96%; /* 15.36px */
    text-transform: uppercase;
    border-radius: 9px;
    border: 1.509px solid #fff;
    background: #fdc920;
    box-shadow: 0px -5px 0px 0px #ce6000 inset;
    width: 90px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-coin {
    position: absolute;
    right: 0;
    bottom: 0;
    img {
      width: 57px;
    }
    span {
      display: block;
      color: #fff;
      text-align: center;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #3c72ff;
      font-family: ErasBoldITC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
    }
  }
`;

export const BlockInvitedFriend = styled.div`
  margin-top: 14px;
`;
export const BlockInvitedFriendInner = styled.div`
  li {
    .position-left {
      img {
        width: 37px;
      }
    }
    .content-position {
      &:before {
        width: 74%;
      }
    }
    .content-user {
      &:before {
        width: 27%;
      }
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      .content-position {
        &:before {
          background-color: #6e8ac6;
          width: 74%;
        }
        &:after {
          content: none;
        }
      }
      .content-user {
        &:before {
          background-color: #4e6ab0;
        }
      }
    }
  }
  ${ContentleaderboardTitle} {
    padding: 10px 20px;
    margin-bottom: 10px;
  }
  ${Contentleaderboard} {
    height: calc(100vh - 420px);
    height: calc(100svh - 420px);
    padding-bottom: 21px;
  }
  li {
    .content-position {
      gap: 0;
    }
  }
  .text-rank {
    color: #fff;
    font-family: "Berlin Sans FB Demi";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 96%; /* 15.36px */
    letter-spacing: -0.64px;
    text-transform: uppercase;
  }
  .content-right {
    display: flex;
    align-items: center;
    gap: 5px;
    span {
      overflow: hidden;
      color: var(--Neutral-50, #f7f7f8);
      text-align: right;
      text-overflow: ellipsis;
      font-family: "Berlin Sans FB Demi";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 25px */
      flex-shrink: 0;
    }
  }
  .content-user {
    span {
      color: #fff;
      text-align: right;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #1b2036;
      font-family: "Berlin Sans FB Demi";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 96%; /* 15.36px */
    }
  }
  ${MyRank} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: #fff;
      font-family: "Berlin Sans FB Demi";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 96%; /* 15.36px */
      letter-spacing: -0.64px;
      text-transform: uppercase;
    }
    button {
      color: #ff6200;
      text-align: center;
      font-family: "Berlin Sans FB Demi";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 96%; /* 15.36px */
      text-transform: uppercase;
      border-radius: 9px;
      border: 1.509px solid #fff;
      background: #fdc920;
      box-shadow: 0px -5px 0px 0px #ce6000 inset;
      width: 190px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;
export const ListInvitedFriend = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 9px;
    background: #242939;
    padding: 11px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    .content-left {
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
      h3 {
        color: var(--Neutral-50, #f7f7f8);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #3b72ff;
        font-family: "Berlin Sans FB Demi";
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 21.25px */
        letter-spacing: 0.17px;
      }
    }
    .level {
      color: var(--Neutral-50, #f7f7f8);
      text-align: right;
      font-family: ErasBoldITC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1%;
    }
  }
`;
export const ButtonInvite = styled.button`
  margin-top: auto;
  display: block;
  width: 20px;
  border-radius: 9px;
  border: 2px solid #1000c3;
  background: linear-gradient(180deg, #7aafff 0%, #236ee0 100%);
  box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.25) inset;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 0.9333948493003845;
  -webkit-text-stroke-color: #3c72ff;
  font-family: ErasBoldITC;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  img {
    margin-right: 5px;
    width: 34px;
  }
  .name-user {
    overflow: hidden;
    color: var(--Neutral-50, #f7f7f8);
    text-overflow: ellipsis;
    font-family: "Berlin Sans FB Demi";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 18.75px */
  }
`;
