import styled from 'styled-components';
import nav_bg from '../../assets/Game/nav_bg.jpg';

export const GameFooterWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
`;
export const GameNav = styled.ul`
  display: flex;
  align-items: center;
  gap: 1px;
  :first-child {
    border-top-left-radius: 20px;
    & > a {
      &.active {
        &::before {
          border-top-left-radius: 20px;
        }
      }
    }
  }
  :last-child {
    border-top-right-radius: 20px;
    & > a {
      &.active {
        &::before {
          border-top-right-radius: 20px;
        }
      }
    }
  }
  & > li {
    width: calc((100% - 3px) / 4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    background: #ffb854;
    border-top: 5px solid #f1ca93;
    & > a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 5;
      width: 100%;
      height: 100%;
      padding-bottom: 15px;
      &.active {
        width: 100%;
        height: 100%;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          border-radius: 6px;
          background: #f7a533;
          z-index: -1;
          pointer-events: none;
        }

        p {
          color: #854d00;
          text-align: center;
          font-family: 'Eras Bold ITC';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%; /* 16.25px */
          letter-spacing: -0.78px;
        }
      }
    }
    figure {
      width: 53px;
      img {
        width: 100%;
      }
    }
    p {
      color: #d67d00;
      text-align: center;
      font-family: 'Eras Bold ITC';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 16.25px */
      letter-spacing: -0.78px;
    }
  }
`;
