import styled from "styled-components";
import bgLeaderboard from "../../assets/Game/bg_leaderboard.webp";

export const ContentLeaderboard = styled.div`
  /* padding-top: 80px; */
`;
export const ContentLeaderboardWrapper = styled.div`
  min-height: calc(100vh - 87px);
  min-height: calc(100svh - 87px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ContentLeaderboardInner = styled.div`
  background: url(${bgLeaderboard}) center / auto 530px no-repeat;
  position: relative;
  min-height: 620px;
`;
export const ButtonClose = styled.div`
  width: 28px;
  position: absolute;
  right: 0;
  top: 110px;
  img {
    width: 100%;
  }
`;
export const ContentTop = styled.div`
  padding-top: 265px;
`;
export const TitleLeaderboard = styled.p`
  color: var(--Neutral-50, #f7f7f8);
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--Orange-500, #dc622e);
  font-family: "ErasBoldITC";
  font-size: 26.818px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 8px;
`;
export const Listleaderboard = styled.ul`
  max-width: 290px;
  margin: 0 auto;
  li {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
    &:nth-child(4),
    &:nth-child(5) {
      .image-position {
        width: 35px;
        top: -4px;
        left: -6px;
      }
    }
    .image-position {
      position: absolute;
      top: -6px;
      left: -10px;
      width: 40px;
    }
    .content-user {
      max-width: 77%;
      width: 100%;
      margin-left: auto;
      border-radius: 49px;
      background: #242939;
      padding: 13px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p,
      span {
        display: block;
        color: var(--Neutral-50, #f7f7f8);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #6590ff;
        font-family: "ErasBoldITC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
      }
    }
  }
`;

export const ImageleaderboardBottom = styled.div`
  width: 430px;
  position: fixed;
  bottom: 10px;
  z-index: 101;
  img {
    width: 100%;
  }
`;
