import { ListToken } from '../styled';
import { Coins } from 'ton3-core';
import { convertFixed } from '../../../utils/convertFormatNumber';
import { useSelector } from 'react-redux';

const WalletInfo = ({ balance, address }: any) => {
  const { listAsset } = useSelector((state: any) => state.point);

  return (
    <>
      <ListToken>
        {listAsset ? (
          listAsset?.map((item: any, index: number) => (
            <li key={index}>
              <div className="content-coin">
                <div className="img-coin">
                  <img src={item.image} alt={item.symbol} />
                </div>
                <h3>{item.symbol}</h3>
              </div>
              <p className="price">
                {convertFixed(
                  Number(Coins.fromNano(item?.balance, item?.decimals))
                )}
              </p>
            </li>
          ))
        ) : (
          <p></p>
        )}
      </ListToken>
    </>
  );
};

export default WalletInfo;
