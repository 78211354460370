import { useEffect, useState } from 'react';
import { EarnDailyButton } from '../Earn/styled';
import {
  ContentContainer,
  ContentHeader,
  ContentInput,
  ContentStep,
  ContentSteps,
  ContentTitle,
  ContentWrap,
} from './styled';
import { KIBBLE_API } from '../../services/api';
import { isValidUrl } from '../../utils/isUrl';
import { useDispatch } from '../../redux/store';
import { getDataListTask, getToastMess } from '../../redux/features/pointSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const listStatus = [
  {
    title: 'Content Submit',
    status: '',
  },
];

export default function ContentPage() {
  const [statusActive, setStatusActive] = useState('');
  const [link, setLink] = useState('');
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [currentTask, setCurrentTask] = useState<any>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmitUrlContent = async () => {
    try {
      const isLink = isValidUrl(link);
      if (!isLink) {
        dispatch(
          getToastMess({
            message: 'Invalid Url',
            status: false,
          })
        );
        return;
      }
      setIsSubmiting(true);
      await KIBBLE_API.submitTaskComplete({
        id: currentTask?.task_id,
        is_content_creation: currentTask.is_content_creation,
        link,
      });
      dispatch(
        getToastMess({
          message: 'Validating!',
          status: true,
        })
      );
      dispatch(getDataListTask());
      setStatusActive('PENDING');
      setIsSubmiting(false);
      setLink('');
      navigate('/earn');
    } catch (error) {
      console.log('====================================');
      console.log('handleSubmitUrlContent err', error);
      console.log('====================================');
      setIsSubmiting(false);
      dispatch(
        getToastMess({
          message: 'Try again',
          status: false,
        })
      );
    }
  };

  const handleFindCurrentTask = async (id: string) => {
    try {
      const { data } = await KIBBLE_API.getListTask();
      const taskActive = data.data.find((item: any) => item.task_id === id);
      if (!taskActive) return;
      setCurrentTask(taskActive);
    } catch (error) {
      console.log('====================================');
      console.log('handleFindCurrentTask err', error);
      console.log('====================================');
    }
  };

  useEffect(() => {
    const idTask = localStorage.getItem('taskContentId');
    if (idTask) {
      handleFindCurrentTask(idTask);
    }
  }, []);

  return (
    <ContentContainer className='animate__animated animate__fadeIn'>
      <ContentWrap>
        <ContentHeader>
          <div className="img-header">
            <img src="/static/banner.png" alt="banner" />
          </div>
        </ContentHeader>
        <ContentTitle>
          <div className="img-social">
            <img
              src={
                currentTask
                  ? currentTask?.thumbnail_info?.file_path
                  : '/static/img-yt.png'
              }
              alt=""
            />
          </div>
          <h2>
            After publishing your work, kindly submit the URL for review below
          </h2>
        </ContentTitle>
        <ContentInput>
          <p>Content url</p>
          <div className="input-url">
            <input
              type="text"
              placeholder="https://"
              onChange={(e: any) => {
                setLink(e.target.value);
              }}
              disabled={isSubmiting || statusActive === 'PENDING'}
            />
          </div>
          <EarnDailyButton
            disabled={isSubmiting || statusActive === 'PENDING'}
            onClick={handleSubmitUrlContent}
          >
            <span>
              {statusActive === 'PENDING' || isSubmiting
                ? 'Validating...'
                : 'Submit'}
            </span>
          </EarnDailyButton>
        </ContentInput>
        <ContentSteps>
          {listStatus.map((item: any, index: number) => {
            return (
              <div
                className={
                  item?.status === statusActive ? 'active-status' : 'no-active'
                }
              >
                <ContentStep>
                  <div className="title">
                    {item?.status === statusActive ||
                    statusActive === 'REJECTED' ? (
                      <div className="img-check">
                        <img src="/static/icon-check.png" alt="check" />
                      </div>
                    ) : (
                      <p>{index + 1}</p>
                    )}
                  </div>
                  <h2>
                    {statusActive === 'REJECTED' ? 'Rejected' : item.title}
                  </h2>
                </ContentStep>
              </div>
            );
          })}
        </ContentSteps>
      </ContentWrap>
    </ContentContainer>
  );
}
