import { ModalContainer } from './modal';
import {
  ButtonRefill,
  ModalRefillEnergyContainer,
  ModalRefillEnergyContent,
  ModalRefillEnergyContentWrap,
  ModalRefillEnergyContentWrapTitle,
} from './styleModalBuyEnergy';

type ModalBuyEnergyType = {
  onClose: () => void;
  time: string;
  onSubmit?: () => void;
};

export default function ModalRefillEnergy({
  onClose,
  onSubmit,
  time,
}: ModalBuyEnergyType) {
  return (
    <ModalContainer className="animate__animated animate__fadeIn">
      <ModalRefillEnergyContainer>
        <div className="img-close" onClick={onClose}>
          <img src="/static/img-close.png" alt="close" />
        </div>
        <div className="img-bg-refill">
          <img src="/static/bg-modal-refill-energy.png" alt="" />
        </div>
        <ModalRefillEnergyContent>
          <ModalRefillEnergyContentWrap>
            <div className="img-clock">
              <img src="/static/icon-clock.png" alt="clock" />
            </div>
            <ModalRefillEnergyContentWrapTitle>
              <p>ENERGY REFILL IN</p>
              <h1>{time}</h1>
            </ModalRefillEnergyContentWrapTitle>
          </ModalRefillEnergyContentWrap>
         
          <ButtonRefill onClick={onSubmit}>
            <span>Re-Boost</span>
          </ButtonRefill>
        </ModalRefillEnergyContent>
        <div className="img-bottom-right">
          <img src="/static/img-bottom-right.png" alt="right" />
        </div>
      </ModalRefillEnergyContainer>
    </ModalContainer>
  );
}
