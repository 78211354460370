import styled from 'styled-components';
import bg01 from '../../assets/Game/friends/bg_list_01.webp';
import bg02 from '../../assets/Game/friends/bg_list_02.webp';
import logoUser from '../../assets/Game/friends/logo_user.webp';

export const FriendContainer = styled.div`
  margin-top: 10px;
`;
export const FriendWrapper = styled.div`
  padding-top: 120px;
  max-width: 350px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 240px);
  height: calc(100svh - 240px);
`;
export const FriendListTop = styled.ul`
  max-height: 200px;
  overflow: auto;
  li {
    border-radius: 12px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      color: var(--Neutral-50, #f7f7f8);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #3b72ff;
      font-family: 'ErasBoldITC';
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 3px;
    }
    p {
      color: var(--trionn-com-nero, #fff);
      font-family: 'ErasBoldITC';
      font-size: 12px;
      font-style: normal;
      line-height: 1;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &:nth-child(1) {
      background: #a0b5ff;
      .content-right {
        padding: 5px 0;
        /* background: url(${bg01}) center / cover no-repeat; */
      }
    }
    &:nth-child(2) {
      background: #ffd15b;
      .content-right {
        padding: 5px 0;
        /* background: url(${bg02}) center / cover no-repeat; */
        span {
          -webkit-text-stroke-color: #ff5502;
        }
      }
      h3 {
        color: #f7f7f8;
      }
      p {
        color: #121315;
      }
    }
    button {
      border-radius: 7px;
      border: 1px solid #1000c3;
      background: linear-gradient(180deg, #7aafff 0%, #236ee0 100%);
      box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset;
      padding: 4px 15px;
      color: #fff;
      text-align: center;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #3c72ff;
      font-family: ErasBoldITC;
      font-size: 13px;
      margin: 0 auto;
      cursor: pointer;
      margin-top: 5px;
    }
    .task-claim {
      button {
        margin-top: 5px;
        display: block;
        color: #fff;
        text-align: center;
        text-shadow: 0px 1.509px 0px #d94500;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fd7f01;
        font-family: ErasBoldITC;
        font-size: 13px;
        line-height: 1;
        border-radius: 6.79px;
        border: 1.509px solid #fff;
        background: linear-gradient(180deg, #ffd902 0%, #ff5602 100%);
        box-shadow: 0px -5px 0px 0px #fd3801 inset;
        padding: 4px 15px;
        cursor: pointer;
      }
    }
  }
  .content-right {
    text-align: center;
    width: 81px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 26px;
      margin: 0 auto 5px;
    }
    span {
      display: block;
      color: #fff;
      text-align: center;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #3c72ff;
      font-family: 'ErasBoldITC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
    }
  }
`;

export const BlockInvitedFriend = styled.div`
  margin-top: 14px;
  .title-invited {
    color: var(--Neutral-50, #f7f7f8);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #3b72ff;
    font-family: 'ErasBoldITC';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 10px;
    position: relative;
    padding-left: 30px;
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      margin-top: -14px;
      left: 0;
      background: url(${logoUser}) center / contain no-repeat;
      width: 26px;
      height: 26px;
    }
  }
`;
export const BlockBtnInvitedFriend = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  max-width: 200px;
`;

export const ListInvitedFriend = styled.ul`
  max-height: calc(100vh - 550px);
  overflow: auto;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 9px;
    background: #242939;
    padding: 11px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    .content-left {
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
      h4 {
        color: var(--Neutral-50, #f7f7f8);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #6590ff;
        font-family: 'ErasBoldITC';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1%;
      }
    }
    .level {
      color: var(--Neutral-50, #f7f7f8);
      text-align: right;
      font-family: 'ErasBoldITC';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1%;
    }
  }
`;
export const ButtonInvite = styled.button`
  margin-top: 10px;
  display: block;
  width: 100%;
  border-radius: 9px;
  border: 2px solid #1000c3;
  background: linear-gradient(180deg, #7aafff 0%, #236ee0 100%);
  box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.25) inset;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 0.9333948493003845;
  -webkit-text-stroke-color: #3c72ff;
  font-family: 'ErasBoldITC';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  img {
    margin-right: 5px;
    width: 34px;
  }
`;
