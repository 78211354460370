import { styled } from 'styled-components';

export const ToastContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 9999;
`;

export const ToastWrap = styled.div`
  padding: 20px 10px;
  position: relative;
  z-index: 999;
  .img-status {
    width: 200px;
    position: absolute;
    right: 20px;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  p {
    color: #fff;
    text-align: center;
    text-shadow: 0px 3.593px 0px rgba(0, 0, 0, 0.25);
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: #ff6b04;
    font-family: 'Luckiest Guy', cursive;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 62.884px */
    right: 20px;
    top: 33px;
    z-index: 2;
    position: relative;
    width: 200px;
  }
`;
