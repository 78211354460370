import { styled } from 'styled-components';

export const ModalBuyEnergyContainer = styled.div`
  height: 385px;
  width: 295px;
  margin: 0 auto;
  margin-top: -150px;
  background-image: url('/static/bg-modal-energy.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 20px;
  z-index: 1;
  .img-bottom-right {
    max-width: 80px;
    position: absolute;
    bottom: -5px;
    right: 0;
    img {
      width: 100%;
    }
  }
  .img-close {
    max-width: 25px;
    position: absolute;
    right: 18px;
    top: 10px;
    z-index: 99;
    img {
      width: 100%;
    }
  }
  h3 {
    overflow: hidden;
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    text-overflow: ellipsis;
    font-family: 'Berlin Sans FB Demi';
    font-size: 20.035px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 25.043px */
    position: absolute;
    transform: rotate(-45deg);
    top: 18px;
    left: 5px;
  }
`;

export const ModalRefillEnergyContainer = styled.div`
  width: 295px;
  margin: 0 auto;
  margin-top: -250px;
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 1;
  .img-bg-refill {
    max-width: 300px;
    position: absolute;
    z-index: -1;
    border-radius: 8px;
    top: 0;
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
  .img-bottom-right {
    max-width: 80px;
    position: absolute;
    bottom: -10px;
    right: 0;
    img {
      width: 100%;
      border-bottom-right-radius: 20px;
    }
  }
  .img-close {
    max-width: 25px;
    position: absolute;
    right: 5px;
    top: 0px;
    z-index: 99;
    img {
      width: 100%;
    }
  }
  h3 {
    overflow: hidden;
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    text-overflow: ellipsis;
    font-family: 'Berlin Sans FB Demi';
    font-size: 20.035px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 25.043px */
    position: absolute;
    transform: rotate(-45deg);
    top: 18px;
    left: 5px;
  }
`;

export const ModalRefillEnergyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 155px;
  border-radius: 20px;
  h1 {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 3.754px 0px #B72E00;
    font-family: "Coda Caption";
    font-size: 30.036px;
    font-style: normal;
    font-weight: 800;
    line-height: 96%; /* 28.834px */
    letter-spacing: -1.802px;
    text-transform: lowercase;
  }
`

export const ModalRefillEnergyContentWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .img-clock {
    width: 75px;
    margin-top: 10px;
    min-height: 40px;
    img {
      width: 100%;
    }
  }
`

export const ModalRefillEnergyContentWrapTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  p {
    color: #FFF8F8;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #703500;
    font-family: "Berlin Sans FB Demi";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
    letter-spacing: -0.8px;
  }
`

export const ButtonRefill = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 20px;
  padding: var(--Click, 9px) var(--Click, 20px) var(--Click, 14px)
    var(--Click, 20px);
  justify-content: center;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 9px;
  border: 2px solid #fff;
  background: #FF8E00;
  box-shadow: 0px 5px 0px 0px #CE6000;
  margin-top: 20px;
  span {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 1.877px 0px #482200;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #703500;
    font-family: "Berlin Sans FB Demi";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 78%; /* 18.274px */
  }
`

export const ModalBuyEnergyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    margin-top: 180px;
    color: #fff;
    text-align: center;
    text-shadow: 0px 3.707px 0px #482200;
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: #703500;
    font-family: 'Berlin Sans FB Demi';
    font-size: 53px;
    font-style: normal;
    font-weight: 700;
    line-height: 78%; /* 40.483px */
  }
  h2 {
    margin-top: 15px;
    color: #fff;
    text-align: center;
    text-shadow: 0px 2.162px 0px rgba(0, 0, 0, 0.83);
    font-family: 'Berlin Sans FB Demi';
    font-size: 30.272px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 36.326px */
    letter-spacing: -1.211px;
    text-transform: uppercase;
  }
  p {
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 112%; /* 11.2px */
    letter-spacing: -0.4px;
    margin-bottom: 5px;
  }
`;

export const ButtonBuy = styled.div`
  display: inline-flex;
  padding: var(--Click, 12.611px) var(--Click, 57.008px) var(--Click, 14.256px)
    var(--Click, 54.931px);
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  border: 1.509px solid #fff;
  background: #fdc920;
  box-shadow: 0px -5px 0px 0px #ce6000 inset;
  align-items: center;
  justify-content: center;
  .img-coin {
    max-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: flex-end;
    img {
      width: 100%;
    }
  }
  span {
    color: #ff6200;
    text-align: center;
    font-family: 'Coda Caption';
    font-size: 20.245px;
    font-style: normal;
    font-weight: 800;
    line-height: 96%; /* 19.435px */
    letter-spacing: -1.012px;
    text-transform: lowercase;
  }
`;
