import {
  AssetsContainer,
  AssetsContent,
  AssetsContentBottom,
  AssetsContentInner,
  AssetsContentTop,
  AssetsContentWallet,
  AssetsWrapper,
  ButtonShop,
  ConnectWallet,
  Total,
  TotalInner,
} from './styled';
import icon01 from '../../assets/Game/Assets/icn_01.svg';
import product01 from '../../assets/Game/Assets/product_img_01.png';
import product02 from '../../assets/Game/Assets/product_img_02.png';
import product03 from '../../assets/Game/Assets/product_img_03.png';
import icnUser from '../../assets/Game/Assets/icn_user.png';
import { useEffect, useState } from 'react';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import WalletModalInfo from '../../components/Common/WalletInfo';
import AssetsShopV2 from '../Assets/AssetsShopV2';
import { convertFixed } from '../../utils/convertFormatNumber';
import WalletInfo from './WalletInfo';
import WebApp from '@twa-dev/sdk';
import { postEvent } from '@tma.js/sdk-react';
import { AssetsWrapTab } from '../Assets/AssetsShopV2/styled';
import { useSelector } from 'react-redux';
import { Coins } from 'ton3-core';
import { useDispatch } from '../../redux/store';
import { getDataListAsset } from '../../redux/features/pointSlice';

const AssetsNew = () => {
  const [showShop, setShowShop] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const [overlayPremium, setOverlayPremium] = useState(false);
  const [tonTokenInWallet, setTonTokenInWallet] = useState<any>(null);
  const address = useTonAddress();
  const { listAsset } = useSelector((state: any) => state.point);
  const dispatch = useDispatch();

  const handleShowShop = () => {
    setShowShop(true);
    WebApp.BackButton.show();
  };

  const hiddenShop = () => {
    setShowShop(false);
  };
  //connect wallet
  const onpenWalletInfo = async () => {
    setOpenConfirmModal(true);
  };
  const handleCancel = async () => {
    setOpenConfirmModal(false);
  };

  // wallet info
  useEffect(() => {
    postEvent('web_app_setup_back_button', {
      is_visible: showShop,
    });
    WebApp.onEvent('backButtonClicked', hiddenShop);
    return () => {
      WebApp.offEvent('backButtonClicked', hiddenShop);
    };
  }, [showShop]);

  useEffect(() => {
    if (listAsset && listAsset.length > 0) {
      const tonToken = listAsset.find(
        (item: any) =>
          item.contract_address === process.env.REACT_APP_TON_ADDRESS
      );
      setTonTokenInWallet(tonToken);
    } else {
      dispatch(getDataListAsset(address));
    }
  }, [listAsset, address]);

  return (
    <AssetsContainer
      className={`${
        showShop
          ? 'is-shop animate__animated animate__fadeIn'
          : 'animate__animated animate__fadeIn'
      } ${
        overlayPremium
          ? 'is-overlay animate__animated animate__fadeIn'
          : 'animate__animated animate__fadeIn'
      }`}
    >
      <AssetsWrapper>
        <AssetsContent className={showShop ? 'is-shop' : ''}>
          <AssetsContentTop>
            {!showShop ? (
              <AssetsWrapTab>
                <ConnectWallet
                  onClick={() => {
                    !address ? tonConnectUI.openModal() : onpenWalletInfo();
                  }}
                >
                  {address ? (
                    <div className="block-user">
                      <img src={icnUser} alt="User" />
                      {address.slice(0, 4)}...{address.slice(-2)}
                    </div>
                  ) : (
                    'Connect Wallet'
                  )}
                </ConnectWallet>
                <ButtonShop onClick={handleShowShop}>
                  <p>KIBBLE SHOP</p>
                </ButtonShop>
              </AssetsWrapTab>
            ) : (
              <></>
            )}
          </AssetsContentTop>
          <AssetsContentInner>
            {!showShop ? (
              <AssetsContentBottom>
                <AssetsContentWallet>
                  <h3>Your wallet assets</h3>
                  <img src="/Assets/icon_asset_01.svg" alt="" />
                </AssetsContentWallet>
                {address && <WalletInfo balance={0} address={address} />}
                <Total>
                  <TotalInner>
                    <p className="total-balance">TON balance</p>
                    {}
                    <p className="price">
                      {tonTokenInWallet &&
                        convertFixed(
                          Number(
                            Coins.fromNano(
                              tonTokenInWallet?.balance,
                              tonTokenInWallet?.decimals
                            )
                          )
                        )}{' '}
                      TON
                    </p>
                  </TotalInner>
                </Total>
              </AssetsContentBottom>
            ) : (
              <>
                {/* <ListProduct>
                  {listProduct.map((item: any) => (
                    <ItemProduct item={item} />
                  ))}
                </ListProduct> */}
                <AssetsShopV2 setOverlayPremium={setOverlayPremium} />
              </>
            )}
          </AssetsContentInner>
        </AssetsContent>
        <WalletModalInfo
          title="YOUR WALLET"
          open={openConfirmModal}
          cancel={handleCancel}
          address={address}
        />
      </AssetsWrapper>
    </AssetsContainer>
  );
};

const listToken = [
  {
    icon: icon01,
    name: 'KIB POINT',
    price: '144,932,982,328',
  },
  {
    icon: icon01,
    name: 'KIB POINT',
    price: '144,932,982,328',
  },
  {
    icon: icon01,
    name: 'KIB POINT',
    price: '144,932,982,328',
  },
  {
    icon: icon01,
    name: 'KIB POINT',
    price: '144,932,982,328',
  },
  {
    icon: icon01,
    name: 'KIB POINT',
    price: '144,932,982,328',
  },
];

const listProduct = [
  {
    id: 1,
    img: product01,
    price: 200000,
    priceShort: 100,
  },
  {
    id: 2,
    img: product02,
    price: 500000,
    priceShort: 300,
  },
  {
    id: 3,
    img: product03,
    isFull: true,
    price: 20000000,
    priceShort: 2000,
  },
];

export default AssetsNew;
