import styled from 'styled-components';
import bg from '../../assets/Game/Assets/bg_01.png';
import bgShop from '../../assets/Game/Assets/bg_shop.png';
import iconShop from '../../assets/Game/Assets/icn_02.png';
import bgPoint from '../../assets/Game/Assets/total_point_kibble.png';

export const AssetsContainer = styled.div`
  padding: 80px 15px 0;
  position: relative;
  &.is-shop {
    padding: 0;
  }
  &.is-overlay {
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0,0,0,.5);
      z-index: 2;
      pointer-events: none;
      opacity: 1;
      transition: all .26s ease;
    }
  }
`;
export const AssetsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const AssetsContentInner = styled.div`
  padding: 0 12px;
  border-radius: 12px;
  background: #1b2036;
  box-shadow: 0px -7px 4px 0px #03050d inset, 0px 6px 0px 0px #4b5dad inset;
`;
export const AssetsContent = styled.div`
  color: var(--Neutral-50, #f7f7f8);
  text-align: center;
  font-family: ErasBoldITC;
  font-size: 34.621px;
  font-style: normal;
  font-weight: 400;
  min-height: 553px;
  width: 100%;
  &.is-shop {
    /* padding-top: 70px; */
    ${AssetsContentInner} {
      box-shadow: unset;
      background: unset;
      padding: unset;
      height: 85vh;
      height: 85svh;
      overflow: auto;
    }
  }
`;

export const AssetsContentTop = styled.div`
  /* padding: 10px 0; */
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 10px;
  .tab-active {
    border: 1.509px solid #fff;
    background: linear-gradient(180deg, #ffd902 0%, #ff5602 100%);
    box-shadow: 0px -5px 0px 0px #fd3801 inset;
  }
`;
export const ConnectWallet = styled.button`
  flex-shrink: 0;
  display: block;
  width: 50%;
  min-height: 54px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 96%; /* 15.36px */
  border-radius: 9px;
  border: 1.509px solid #fff;
  background: linear-gradient(180deg, #ffd902 0%, #ff5602 100%);
  box-shadow: 0px -5px 0px 0px #fd3801 inset;
  .block-user {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 30px;
      margin-right: 5px;
    }
  }
`;
export const ButtonShop = styled.div`
  max-width: 50%;
  width: 100%;
  border-radius: 9px;
  border: 1.509px solid #fff;
  background: linear-gradient(180deg, #acacac 0%, #505050 100%);
  box-shadow: 0px -5px 0px 0px #434343 inset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 30px;
  &:before {
    position: absolute;
    content: '';
    top: 50%;
    margin-top: -13px;
    left: 15px;
    background: url(${iconShop}) center / contain no-repeat;
    width: 31px;
    height: 23px;
  }
  p {
    color: #fff;
    text-align: center;
    font-family: ErasBoldITC;
    font-size: 16px;
    line-height: 1;
  }
  .block-time {
    color: #b6b6b6;
    text-align: center;
    text-shadow: 0px 1.509px 0px #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 15px */
  }
  @media screen and (max-width: 418px) {
    &:before {
      left: 10px;
    }
    p {
      font-size: 14px;
    }
  }
`;
export const AssetsContentBottom = styled.div``;
export const AssetsContentWallet = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 27px 0 15px;
  h3 {
    color: #fff;
    font-family: 'Berlin Sans FB Demi';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 96%; /* 15.36px */
    letter-spacing: -0.64px;
    text-transform: uppercase;
  }
`;
export const Total = styled.div`
  border-top: 2px solid #3b488a;
  background: #37406d;
  padding: 7px 13px 13px 11px;
  border-radius: 0 0 12px 12px;
  box-shadow: 0px -7px 4px 0px #03050d inset;
  margin: 20px -12px 0;
`;
export const TotalInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url('/Assets/bg_product.png') center / 100% auto no-repeat;
  padding: 11px 20px;
  position: relative;
  z-index: 1;
  .total-balance {
    color: #fff;
    font-family: 'Berlin Sans FB Demi';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 20px */
  }
  .price {
    color: #fff;
    text-align: right;
    font-family: 'Berlin Sans FB Demi';
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 96%; /* 15.36px */
    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      width: 57%;
      z-index: -1;
      background: url('/Assets/bg_product_02.png') center / 100% auto no-repeat;
    }
  }
`;
export const ListToken = styled.ul`
  max-height: 342px;
  overflow: auto;
  height: calc(100vh - 460px);
  height: calc(100svh - 460px);
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url('/Assets/bg_product.png') center / 100% auto no-repeat;
    padding: 0 20px;
    position: relative;
    height: 42px;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    .content-coin {
      display: flex;
      align-items: center;
    }
    .img-coin {
      margin-right: 5px;
      line-height: 1;
      position: relative;
      top: 2px;
    }
    img {
      width: 26px;
      border-radius: 50%;
    }
    h3 {
      overflow: hidden;
      color: var(--Neutral-50, #f7f7f8);
      text-overflow: ellipsis;
      font-family: 'Berlin Sans FB Demi';
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 18.75px */
    }
  }
  .price {
    font-family: 'Berlin Sans FB Demi';
    width: 55%;
    color: #fff;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 96%; /* 15.36px */
    z-index: 1;
    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      width: 55%;
      z-index: -1;
      background: url('/Assets/bg_product_02.png') center / 100% auto no-repeat;
    }
  }
`;

//content shop
export const BgTop = styled.figure`
  position: absolute;
  top: 79px;
  left: 0;
  width: 100%;
  overflow: hidden;
  ${AssetsContentTop} {
    overflow: hidden;
  }
`;
export const ButtonBack = styled.div`
  width: 117px;
  img {
    width: 100%;
  }
`;
export const ContentPoint = styled.div`
  background: url(${bgPoint}) center / 156px auto no-repeat;
  width: 156px;
  height: 40px;
  text-align: center;
  span {
    width: 78%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #fff;
    text-align: center;
    font-family: Franklin;
    font-size: 15px;
    font-style: normal;
    line-height: 1;
    text-transform: lowercase;
  }
`;

export const ListProduct = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  li {
    list-style-type: none;
    width: calc(100% / 2 - 10px);
    img {
      width: 100%;
    }
    &.is-full {
      width: 100%;
    }
  }
`;
