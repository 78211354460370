import React, { useEffect, useState } from 'react';
import {
  Contentleaderboard,
  ContentLeaderboard,
  ContentLeaderboardInner,
  ContentleaderboardTitle,
  ContentLeaderboardWrapper,
  ContentTop,
  Listleaderboard,
  ListleaderboardContent,
  MyRank,
  TitleLeaderboard,
} from './styledV2';
import { useSelector } from 'react-redux';
import { useDispatch } from '../../redux/store';
import { getDataLeaderBoard } from '../../redux/features/pointSlice';
import { convertFormatNumber } from '../../utils/convertFormatNumber';
import { convertBigNumberToText } from '../../utils/convertBigNumber';

const LeaderboardV2 = () => {
  const [currentPage, setCurrentPage] = useState<any>(1);

  const { leaderBoard, infoUser, maxPoint } = useSelector((state: any) => state.point);
  const dispatch = useDispatch();

  const handleLoadMore = (e: any) => {
    try {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const position = Math.ceil(
        (scrollTop / (scrollHeight - clientHeight)) * 100
      );
      if (position > 80 && currentPage < 20) {
        dispatch(
          getDataLeaderBoard({
            page: currentPage + 1,
          })
        );
        setCurrentPage(currentPage + 1);
        e.target.clientHeight = 0;
      }
    } catch (error) {
      // console.log('====================================');
      // console.log('handleLoadMore err', error);
      // console.log('====================================');
    }
  };

  useEffect(() => {
    window.addEventListener('onscroll', handleLoadMore);
    return () => window.removeEventListener('onscroll', handleLoadMore);
  }, []);

  return (
    <ContentLeaderboard className="animate__animated animate__fadeIn">
      <ContentLeaderboardWrapper>
        <ContentLeaderboardInner>
          <ContentTop>
            <TitleLeaderboard>
              <span>LEADER BOARD</span>
            </TitleLeaderboard>
            <Contentleaderboard>
              <ContentleaderboardTitle>
                <div className="title-left">
                  <p className="text-rank">RANK</p>
                </div>
                <div className="content-right">
                  <img src="/images/Leaderboard/icn_rank.png" alt="Rank" />
                </div>
              </ContentleaderboardTitle>
              <ListleaderboardContent>
                <Listleaderboard onScroll={handleLoadMore}>
                  {leaderBoard &&
                    leaderBoard.data.length > 0 &&
                    leaderBoard?.data?.map((item: any, index: number) => (
                      <li key={index}>
                        <div className="content-position">
                          {listTop[index] ? (
                            <div className="position-left">
                              <img
                                className="image-position"
                                src={listTop[index].img}
                                alt={item?.fullname}
                              />
                            </div>
                          ) : (
                            <div className="position-left">
                              <p className="position">{index + 1}</p>
                            </div>
                          )}
                          <p className="name-user">{item?.fullname}</p>
                        </div>
                        <div className="content-user">
                          <span>
                            {item
                              ? item?.point > 1000000
                                ? convertBigNumberToText(Number(item?.point))
                                : convertFormatNumber(
                                    Number(item?.point)
                                  )?.replaceAll('.00', '')
                              : 0}
                          </span>
                        </div>
                      </li>
                    ))}
                </Listleaderboard>
                <MyRank>
                  <div className="my-rank-inner">
                    <div className="content-position">
                      <p className="position">{infoUser?.rank}</p>
                      <p className="name-user">{infoUser?.username}</p>
                    </div>
                    <div className="content-user">
                      <span>
                      {
                        maxPoint
                        ? maxPoint > 1000000
                          ? convertBigNumberToText(Number(maxPoint))
                          : convertFormatNumber(Number(maxPoint))?.replaceAll('.00', '')
                        : 0}
                      </span>
                    </div>
                  </div>
                </MyRank>
              </ListleaderboardContent>
            </Contentleaderboard>
          </ContentTop>
        </ContentLeaderboardInner>
      </ContentLeaderboardWrapper>
    </ContentLeaderboard>
  );
};

const listTop = [
  {
    img: '/images/Leaderboard/icn_top_1.svg',
  },
  {
    img: '/images/Leaderboard/icn_top_2.svg',
  },
  {
    img: '/images/Leaderboard/icn_top_3.svg',
  },
];

export default LeaderboardV2;
