import styled from 'styled-components';
import bg from '../../../assets/Game/Assets/modal_confirm_bg.png';
import bgConfirmation from '../../../assets/Game/Assets/Shop/bg_confirmation.png';
import { InputContainer } from '../../../components/Common/Input/styled';
import { ButtonClose } from '../../Leaderboard/styled';

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 102;
  width: 90%;
`;
export const ModalWrapper = styled.div``;
export const ModalContent = styled.div`
  max-width: 319px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 0 27px;
`;
export const ModalContentTitle = styled.h3`
  width: 288px;
  margin: 0 auto;
  position: absolute;
  top: -33px;
  left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
  }
`;
export const ModalContentInner = styled.div`
  border-radius: 19px;
  border: 5.929px solid #c8507e;
  background: linear-gradient(180deg, #aa3f66 0%, #733577 100%);
  ${ButtonClose} {
    top: -10px;
    right: 0px;
  }
`;
export const ModalText = styled.p`
  color: var(--Neutral-50, #f7f7f8);
  font-family: 'ErasBoldITC';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-align: left;
  position: relative;
  padding-left: 30px;
  text-shadow: none;
  img {
    position: absolute;
    top: -7px;
    left: 0;
    width: 24px;
    margin-right: 5px;
  }
`;
export const ModalCount = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  .image-left {
    width: 69px;
    position: relative;
    top: 6px;
  }
  .content-right {
    line-height: 1;
    .count-number {
      color: var(--Neutral-50, #f7f7f8);
      text-align: center;
      font-family: 'Berlin Sans FB Demi';
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%; /* 21.25px */
      margin-bottom: 3px;
      display: inline-block;
    }
  }
  ${InputContainer} {
    background-color: #242939;
    border-radius: 9px;
    input {
      padding-top: 0px;
      padding-bottom: 0px;
      color: #f7f7f8;
      -webkit-text-stroke: 0;
      text-align: center;
    }
  }
  .decrease,
  .increase {
    width: 34px;
    height: 34px;
    img {
      width: 100%;
    }
  }
  /* .ant-input-prefix,
  .ant-input-suffix {
    color: #5158ff;
    text-shadow: unset;
    box-shadow: unset;
  } */
  .ant-input-affix-wrapper {
    padding: 0 2px 3px;
  }
`;
export const ModalReceive = styled.div`
  border-radius: 12px;
  padding: 18px 10px;
  margin-bottom: 15px;
  background-color: #ffe380;
  position: relative;
  z-index: 1;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(${bgConfirmation}) center / cover no-repeat;
    z-index: -1;
  }
  .text-receive {
    color: var(--trionn-com-woodsmoke, #121315);
    text-align: center;
    font-family: 'ErasBoldITC';
    font-size: 15px;
    font-style: normal;
    line-height: 1;
    -webkit-text-stroke: 0;
    text-shadow: unset;
    margin-bottom: 5px;
    text-shadow: none;
  }
  .content-receive {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    img {
      width: 18px;
    }
    span {
      color: #282828;
      text-align: center;
      font-size: 20.435px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%; /* 25.544px */
    }
  }
`;
export const ButtonConfirm = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 9px;
  border: 1.509px solid #fff;
  background: #fdc920;
  box-shadow: 0px -5px 0px 0px #ce6000 inset;
  min-height: 53px;
  color: #ff6200;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 96%; /* 19.2px */
  letter-spacing: -1px;
  text-transform: lowercase;
  img {
    width: 24px;
    margin-right: 4px;
  }
`;
