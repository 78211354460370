import { useContext, useEffect, useRef, useState } from 'react';
import {
  BannerEnergy,
  BannerPoint,
  ButtonCountDown,
  ButtonReboot,
  GameContainer,
  GameProgress,
  GameProgressBar,
  GameProgressText,
  GameWrapper,
  MonkeyWrap,
  PointAppear,
  RebootEnergy,
  RebootEnergyWrap,
} from './styledv2';

import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { RULE_GAME } from '../../constant';
import {
  getDataEnergy,
  getDataMaxPoint,
  getDataPoint,
  getInfoLevelUser,
  getTimeNextRefill,
  getToastMess,
} from '../../redux/features/pointSlice';
import { sha256EncryptAsync } from '../../utils/encryptData';
import { KIBBLE_API } from '../../services/api';
import { getValueSetting } from '../../utils/getValueSetting';
import { useDebouncedCallback } from 'use-debounce';
import WebApp from '@twa-dev/sdk';
import { useDispatch } from '../../redux/store';
import { useSelector } from 'react-redux';
import { convertBigNumberToText } from '../../utils/convertBigNumber';
import { convertFormatNumber } from '../../utils/convertFormatNumber';
import progress_icon from '../../assets/Game/progress_icon.png';
import icn_point_progress from '../../assets/Game/icn_point_progress.png';
import ModalRefillEnergy from '../Assets/Modal/modalRefillEnergy';
import { formatUnitTime } from '../../utils/formatUnitTime';

const ClickerV2 = () => {
  const {
    energy,
    getPoints,
    infoUser,
    userLevel,
    listFriends,
    timeNextRefill,
    maxPoint,
  } = useSelector((state: any) => state.point);

  const [sumCoin, setSumCoin] = useState(getPoints);
  const [sumMaxPoint, setSumMaxPoint] = useState(maxPoint);
  const [pointAppearArray, setPointAppearArray] = useState<any>([]);
  const [pointState, setPointState] = useState(1);
  const [maxTabProcess, setMaxTabProcess] = useState(150);
  const [imgMonkey, setImgMonkey] = useState('/static/img-monkey-1.png');
  const [tapCount, setTapCount] = useState(0);
  const [tapProgress, setTapProgress] = useState(0);
  const [totalTap, setTotalTap] = useState(0);
  const [isFirstClick, setIsFirstClick] = useState(false);
  const [isShowModalRefillEnergy, setIsShowModalRefillEnergy] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(Date.now());
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSecond] = useState(0);
  const [processCountDown, setProcessCountDown] = useState(0);

  const dispatch = useDispatch();

  const handleClickCoin = async (event: any) => {
    WebApp.HapticFeedback.impactOccurred('light');
    if (!isFirstClick) {
      setImgMonkey('/static/img-monkey-2.png');
      setIsFirstClick(true);
    }
    if (!infoUser) return;
    if (energy < 1) {
      dispatch(
        getToastMess({
          message: 'Out of energy',
          status: false,
        })
      );
      return;
    }
    const now = new Date().getTime();
    localStorage.setItem('lastTimeClick', `${now}`);
    const numOfTap = tapCount + 1;
    setTapCount(numOfTap);
    setTapProgress(tapProgress + 1);
    setTotalTap(totalTap + 1);
    setSumCoin(sumCoin + pointState * userLevel?.pointToPlus);
    setSumMaxPoint(maxPoint + pointState * userLevel?.pointToPlus);
    dispatch(getDataEnergy(energy - 1));
    const newCoinArray: any = [
      ...pointAppearArray,
      { id: Date.now(), text: pointState * userLevel?.pointToPlus },
    ];
    if (event.touches) {
      for (let i = 0; i < event.touches.length; i++) {
        const touch = event.touches[i];
        const x = touch.clientX;
        const y = touch.clientY;
        newCoinArray.push({
          id: Date.now() + i,
          text: pointState * userLevel?.pointToPlus,
          x,
          y,
        });
      }
    } else {
      const x = event.clientX;
      const y = event.clientY;
      newCoinArray.push({
        id: Date.now(),
        text: pointState * userLevel?.pointToPlus,
        x,
        y,
      });
    }
    setPointAppearArray(newCoinArray);
    setLastClickTime(Date.now());
    debounced();
  };

  const debounced = useDebouncedCallback(
    async () => {
      handleChecKTabToSendClick();
    },
    // delay in ms
    1500
  );

  const countDownTime = () => {
    if (timeNextRefill.available) return;

    const currentDate = new Date(timeNextRefill.refillTime);
    const countDownDate = new Date(currentDate.toString()).getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;
      const limitHourCountDown = 1000 * 60 * 60 * 8; // 8 hours
      const processCountDown =
        (limitHourCountDown - distance) / limitHourCountDown;
      setProcessCountDown(processCountDown);

      // Time calculations for days, hours, minutes and seconds
      // Time calculations for days, hours, minutes and seconds
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      setSecond(
        Math.floor((distance % (1000 * 60)) / 1000) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60)) / 1000)
      );

      if (distance <= 0) {
        clearInterval(x);
        setProcessCountDown(100);
        dispatch(getTimeNextRefill());
      }
    }, 1000);
  };

  const handleSendDataClick = async (data?: any) => {
    try {
      await KIBBLE_API.sendNumOfClick(data);
    } catch (error) {
      console.log('====================================');
      console.log('handleSendDataClick error', error);
      console.log('====================================');
    }
  };

  const handleCheckUserUpLevel = () => {
    const dataForSettingLevel: any = getValueSetting(userLevel.level);
    if (userLevel.level < 6) {
      dispatch(
        getInfoLevelUser({
          pointNextLevel: dataForSettingLevel.pointOfLevel,
          pointToPlus: dataForSettingLevel.pointToPlus,
          lv: userLevel.level + 1,
        })
      );
    } else if (listFriends?.total > 1000 && userLevel.level < 9) {
      // check conditions to up level
      dispatch(
        getInfoLevelUser({
          pointNextLevel: dataForSettingLevel.pointOfLevel,
          pointToPlus: dataForSettingLevel.pointToPlus,
          lv: userLevel.level + 1,
        })
      );
    } else if (listFriends?.total > 3000 && userLevel.level < 10) {
      dispatch(
        getInfoLevelUser({
          pointNextLevel: dataForSettingLevel.pointOfLevel,
          pointToPlus: dataForSettingLevel.pointToPlus,
          lv: userLevel.level + 1,
        })
      );
    }
  };

  const handleChecKTabToSendClick = async () => {
    try {
      if (getPoints === 0) return;
      const localPoint = localStorage.getItem('localPoint');
      const points = localPoint ? Number(getPoints) - Number(localPoint) : 0;
      // set new value after caculate
      if (points < 1) return;
      localStorage.setItem('localPoint', getPoints);
      const { data } = await KIBBLE_API.getInfoUser();
      const taps =
        totalTap === 0
          ? Math.round(points / 4)
          : totalTap < data.energy
          ? totalTap
          : data.energy;
      // reset value after caculate
      setTotalTap(0);
      let encryptData = `points=${points}&taps=${taps}`;
      let signatureVerify = await sha256EncryptAsync(encryptData);
      await handleSendDataClick({
        points,
        taps,
        signature: signatureVerify,
      });
    } catch (error) {
      console.log('====================================');
      console.log('ERR handleChecKTabToSendClick', error);
      console.log('====================================');
    }
  };

  const handleRefillEnergy = async () => {
    try {
      if (timeNextRefill && timeNextRefill.available) {
        await handleChecKTabToSendClick();
        const { data } = await KIBBLE_API.refillEnergy();
        dispatch(getTimeNextRefill());
        dispatch(getDataEnergy(data?.energy));
        dispatch(
          getToastMess({
            status: true,
            message: 'Refill successfully',
          })
        );
        setTapProgress(0);
        setMaxTabProcess(RULE_GAME.KEEP_CLICK.one_time.target);
        setPointState(1);
        setImgMonkey('/static/img-monkey-2.png');
      } else {
        setIsShowModalRefillEnergy(true);
      }
    } catch (error) {
      console.log('====================================');
      console.log('handleRefillEnergy err', error);
      console.log('====================================');
    }
  };

  useEffect(() => {
    if (timeNextRefill) {
      countDownTime();
    }
  }, [timeNextRefill]);

  useEffect(() => {
    if (infoUser) {
      dispatch(getDataPoint(sumCoin === 0 ? getPoints : sumCoin));
      if (Number(getPoints) > Number(userLevel.pointNextLevel)) {
        handleCheckUserUpLevel();
      }
    }
  }, [sumCoin]);

  useEffect(() => {
    if (infoUser) {
      dispatch(
        getDataMaxPoint(sumMaxPoint === 0 ? infoUser.max_point : sumMaxPoint)
      );
    }
  }, [sumMaxPoint]);

  useEffect(() => {
    // Adjusting tap count decrement interval based on last click time
    const decrementInterval = Date.now() - lastClickTime < 1000 ? 500 : 200;
    const interval = setInterval(() => {
      setTapCount((prev: any) => Math.max(prev - 5, 0));
      setTapProgress((prev) => Math.max(prev - 5, 0));
    }, decrementInterval);
    return () => {
      clearInterval(interval);
    };
  }, [lastClickTime, tapCount]);

  useEffect(() => {
    // Check tapCount to update pointState
    if (tapCount > RULE_GAME.KEEP_CLICK.one_time.target && pointState === 1) {
      setTapProgress(0);
      setMaxTabProcess(RULE_GAME.KEEP_CLICK.one_time.target);
      setPointState(2);
      setImgMonkey('/static/img-monkey-2.png');
    } else if (
      tapCount > RULE_GAME.KEEP_CLICK.two_time.target &&
      pointState === 2
    ) {
      setTapProgress(0);
      setMaxTabProcess(
        RULE_GAME.KEEP_CLICK.three_time.target -
          RULE_GAME.KEEP_CLICK.two_time.target
      );
      setPointState(3);
      setImgMonkey('/static/img-monkey-2.png');
    } else if (
      tapCount > RULE_GAME.KEEP_CLICK.three_time.target &&
      pointState === 3
    ) {
      setTapProgress(0);
      setMaxTabProcess(1000);
      setPointState(4);
      setImgMonkey('/static/img-monkey-3.png');
    } else if (
      tapCount < RULE_GAME.KEEP_CLICK.one_time.target &&
      pointState === 2
    ) {
      setTapProgress(RULE_GAME.KEEP_CLICK.one_time.target - 1);
      setMaxTabProcess(RULE_GAME.KEEP_CLICK.one_time.target);
      setPointState(1);
      setImgMonkey('/static/img-monkey-2.png');
    } else if (
      tapCount < RULE_GAME.KEEP_CLICK.two_time.target &&
      pointState === 3
    ) {
      setTapProgress(RULE_GAME.KEEP_CLICK.one_time.target - 1);
      setMaxTabProcess(RULE_GAME.KEEP_CLICK.one_time.target);
      setPointState(2);
      setImgMonkey('/static/img-monkey-2.png');
    } else if (
      tapCount < RULE_GAME.KEEP_CLICK.three_time.target &&
      pointState === 4
    ) {
      setTapProgress(
        RULE_GAME.KEEP_CLICK.three_time.target -
          RULE_GAME.KEEP_CLICK.two_time.target -
          1
      );
      setMaxTabProcess(
        RULE_GAME.KEEP_CLICK.three_time.target -
          RULE_GAME.KEEP_CLICK.two_time.target
      );
      setPointState(3);
      setImgMonkey('/static/img-monkey-3.png');
    }
  }, [tapCount]);

  useEffect(() => {
    const intervalPointAppearArray = setInterval(() => {
      const newArr = [...pointAppearArray];
      newArr.pop();
      setPointAppearArray(newArr);
    }, 4000);
    return () => {
      clearInterval(intervalPointAppearArray);
      handleChecKTabToSendClick();
    };
  }, []);

  useEffect(() => {
    if(totalTap !== 0 && totalTap % 50 === 0) {
      handleChecKTabToSendClick()
    }
  }, [totalTap])

  return (
    <GameContainer State={1} className="animate__animated animate__fadeIn">
      {isShowModalRefillEnergy && (
        <ModalRefillEnergy
          time={`${formatUnitTime(hours)}:${formatUnitTime(
            minutes
          )}:${formatUnitTime(seconds)}`}
          onClose={() => {
            setIsShowModalRefillEnergy(false);
          }}
          onSubmit={() => {
            if (!timeNextRefill?.available) {
              setIsShowModalRefillEnergy(false);
            } else {
              handleRefillEnergy();
            }
          }}
        />
      )}
      <RebootEnergy>
        <RebootEnergyWrap
          isCountDown={!timeNextRefill?.available}
          onClick={handleRefillEnergy}
        >
          <div className="img-reboot">
            <img src="/static/img-reboot.png" alt="reboot" />
          </div>
          {timeNextRefill && timeNextRefill.available ? (
            <ButtonReboot>
              <span>ReFill Now</span>
            </ButtonReboot>
          ) : (
            <ButtonCountDown process={processCountDown}>
              <div className="process"></div>
              <p>
                {formatUnitTime(hours)}:{formatUnitTime(minutes)}:
                {formatUnitTime(seconds)}
              </p>
            </ButtonCountDown>
          )}
        </RebootEnergyWrap>
      </RebootEnergy>
      <PointAppear>
        {pointAppearArray.map((item: any, index: number) => {
          return (
            <div
              key={index}
              style={{
                position: 'absolute',
                top: `${item.y}px`,
                left: `${item.x}px`,
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
                zIndex: 100,
              }}
              className="img-count"
            >
              <h1>
                <span>+</span>
                {item.text}
              </h1>
            </div>
          );
        })}
      </PointAppear>
      <div className="img-bg-click">
        <img src="/static/bg-clicker.png" alt="clicker" />
      </div>

      <GameWrapper>
        <BannerPoint>
          <div className="image-banner-point">
            <img src="/static/bg-point.png" alt="" />
          </div>
          <p>
            {maxPoint
              ? maxPoint > 1000000
                ? convertBigNumberToText(Number(maxPoint))
                : convertFormatNumber(Number(maxPoint))?.replaceAll('.00', '')
              : 0}
          </p>
        </BannerPoint>
        <BannerEnergy>
          <div className="image-banner-enrgy">
            <img src="/static/bg-energy.png" alt="" />
          </div>

          <p>
            {energy < 1
              ? 0
              : convertFormatNumber(Math.round(energy))?.replaceAll(
                  '.00',
                  ''
                )}{' '}
            <span>
              /{' '}
              {convertFormatNumber(
                infoUser ? infoUser?.energy_limit : 0
              )?.replaceAll('.00', '')}
            </span>
          </p>
        </BannerEnergy>
        <MonkeyWrap>
          <div className="img-monkey">
            <motion.img
              whileTap={{ scale: 0.9 }}
              onTouchStart={(e) => {
                handleClickCoin(e);
              }}
              src={imgMonkey}
              alt="monkey"
            />
          </div>
        </MonkeyWrap>
        <GameProgress className={'is-show'}>
          <div className="image-energy">
            <img src={progress_icon} alt="" />
          </div>
          <GameProgressText>x{pointState}</GameProgressText>
          <div className="image-point">
            <img src={icn_point_progress} alt="" />
          </div>
          <GameProgressBar Progress={0}>
            <span
              style={{ width: `${(tapProgress / maxTabProcess) * 100}%` }}
            />
          </GameProgressBar>
        </GameProgress>
      </GameWrapper>
    </GameContainer>
  );
};

export default ClickerV2;
