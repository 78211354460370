import { styled } from 'styled-components';

export const LoadingContainer = styled.div`
  height: 100vh;
  height: 100svh;
  position: relative;
  .img-bg-loading {
    max-width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    img {
      width: 100%;
    }
  }
  .img-name {
    max-width: 340px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .img-monkey-loading {
    max-width: calc(100vw - 50px);
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .img-bottom-left {
    max-width: 170px;
    margin: 0 auto;
    position: absolute;
    left: -20px;
    bottom: 0px;
    z-index: 2;
    overflow: hidden;
    img {
      width: 100%;
      margin-bottom: -5px;
    }
  }

  .img-bottom-right {
    max-width: 160px;
    margin: 0 auto;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    overflow: hidden;
    img {
      width: 100%;
      margin-bottom: -5px;
    }
  }
`;

export const LoadingProcess = styled.div`
  width: 60%;
  margin: 0 auto;
  height: 21px;
  background-color: #f4ae62;
  border-top: 2px solid #bf824a;
  /* margin-top: 40px; */
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  p {
    position: relative;
    text-align: center;
    z-index: 2;
    color: #fff;
    text-align: center;
    font-family: 'Berlin Sans FB Demi';
    font-size: 14px;
    margin-top: 2px;
    font-style: normal;
    letter-spacing: -0.5px;
  }
`;

export const LoadingProcessLoaded = styled.div<{ process: number }>`
  width: ${({ process }) => `${process}%`};
  height: 100%;
  background-color: #bc0000;
  border-top: 2px solid #8d0000;
  margin-top: -2px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: skewX(-15deg);
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;
