import React, { useContext, useState } from 'react';
import ModalProduct from '../Modal';
import { ContextProviderWrapper } from '../../../components/Context';
import {
  ListDailBlockCoin,
  ListDailBuy,
  ListDailIconCoin,
  ListDailItemBest,
  ListDailyItem,
} from '../AssetsShopV2/styled';
import icnBest from '../../../assets/Game/Assets/Shop/icn_best.png';
import icnCoin from '../../../assets/Game/Assets/Shop/icn_coin.png';
import icnCoin02 from '../../../assets/Game/Assets/Shop/icn_coin_02.png';

const ItemProduct = ({ item, index, setOverlayPremium }: any) => {
  const [showModalProduct, setShowModalProduct] = useState(false);

  //handle show modal product
  const handleShowModalProduct = () => {
    setShowModalProduct(true);
    setOverlayPremium(true)
  };
  const hiddenShowModalProduct = () => {
    setShowModalProduct(false);
    setOverlayPremium(false)
  };

  return (
    <>
      <ListDailyItem key={index}>
        {item.isBest && (
          <ListDailItemBest>
            <img src={icnBest} alt="Best" />
          </ListDailItemBest>
        )}
        <ListDailIconCoin>
          <img src={icnCoin} alt="Coin" />
        </ListDailIconCoin>
        <h3 className="title-pack">Pack {index + 1}</h3>
        <ListDailBlockCoin>
          <p className="text-receive">{item.textReceiveNew}</p>
          <p className="text-receive-old">{item.textReceiveOld}</p>
        </ListDailBlockCoin>
        <ListDailBuy>
          <img src={icnCoin02} alt="Coin" />
          {item.price}
        </ListDailBuy>
      </ListDailyItem>
      {showModalProduct && (
        <ModalProduct
          item={item}
          index={index}
          hiddenShowModalProduct={hiddenShowModalProduct}
        />
      )}
    </>
  );
};

export default ItemProduct;
