import axios from 'axios';

export const checkUserInGroupChat = async (userID: any) => {
  try {
    const { data } = await axios.get(
      `https://api.telegram.org/bot${process.env.REACT_APP_TOKEN_BOT}/getChatMember?user_id=${userID}&chat_id=${process.env.REACT_APP_ID_GROUP_KIBBLE_CHAT}`
    );
    return data;
  } catch (error) {
    return null;
  }
};
