import {
  BlockInvitedFriend,
  BlockInvitedFriendInner,
  ButtonInvite,
  FriendContainer,
  FriendListTop,
  FriendWrapper,
} from './styledV2';
import {
  Contentleaderboard,
  ContentleaderboardTitle,
  Listleaderboard,
  ListleaderboardContent,
  MyRank,
} from '../Leaderboard/styledV2';
import { useInitData } from '@tma.js/sdk-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from '../../redux/store';
import { KIBBLE_API } from '../../services/api';
import { getDataInfoUser, getDataListFriends, getListPointOfInviteFriends, getToastMess } from '../../redux/features/pointSlice';
import WebApp from '@twa-dev/sdk';
import { CopyOutlined } from '@ant-design/icons';
import { BlockBtnInvitedFriend } from './styled';
import ModalInviteReward from '../Earn/Modal/modalInviteReward';

const FriendsV2 = () => {
  const dataUserTele: any = useInitData();
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [isShowClaimInviteFr, setIsShowClaimInviteFr] = useState<any>(false);
  const [objToClaim, setObjToClaim] = useState<any>(null);

  const { listFriends, listPointOfInviteFriends, infoUser } = useSelector(
    (state: any) => state.point
  );
  const dispatch = useDispatch();

  const {
    initData: { user },
  } = dataUserTele;

  const handleClaimPointFriend = async (is_premium: boolean, point: number) => {
    try {
      const params = {
        is_premium,
      };
      await KIBBLE_API.claimPointInviteFriend(params);
      dispatch(getDataInfoUser(user));
      dispatch(getListPointOfInviteFriends());
      dispatch(getToastMess({
        status: true,
        message: 'Successfully!'
      }))
      setObjToClaim(null)
      setIsShowClaimInviteFr(false)
    } catch (error) {
      console.log('handleClaimPointFriend err', error);
      dispatch(getToastMess({
        status: false,
        message: 'Try again!'
      }))
      setObjToClaim(null)
      setIsShowClaimInviteFr(false)
    }
  };

  const handleInviteFriend = (type: string) => {
    try {
      const referalCode = infoUser && infoUser.custom_code ? infoUser.custom_code : user.id
      const link = `https://t.me/KibbleClickBot/clicker?startapp=${referalCode}`;
      const shareLink = `https://t.me/share/url?url=${encodeURIComponent(
        link
      )}`;
      if (type === 'copy') {
        navigator.clipboard.writeText(link);
        dispatch(
          getToastMess({
            message: 'Copied',
            status: true,
          })
        );
      } else {
        WebApp.openTelegramLink(shareLink);
      }
    } catch (error) {
      console.log('====================================');
      console.log('handleInviteFriend err', error);
      console.log('====================================');
    }
  };

  const handleLoadMore = (e: any) => {
    try {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const position = Math.ceil(
        (scrollTop / (scrollHeight - clientHeight)) * 100
      );
      if (position > 80 && listFriends?.data?.length < listFriends?.total) {
        dispatch(
          getDataListFriends({
            page: currentPage + 1,
          })
        );
        setCurrentPage(currentPage + 1);
        e.target.clientHeight = 0;
      }
    } catch (error) {
      // console.log('====================================');
      // console.log('handleLoadMore err', error);
      // console.log('====================================');
    }
  };

  useEffect(() => {
    if (!listFriends) {
      dispatch(getDataListFriends());
    }
    if (!listPointOfInviteFriends || listPointOfInviteFriends.length === 0) {
      dispatch(getListPointOfInviteFriends());
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener('onscroll', handleLoadMore);
    return () => window.removeEventListener('onscroll', handleLoadMore);
  }, []);

  return (
    <FriendContainer className='animate__animated animate__fadeIn'>
      {
        isShowClaimInviteFr && objToClaim && <ModalInviteReward onClose={() => {
          setIsShowClaimInviteFr(false)
        }} 
        onSubmit={() => {
          handleClaimPointFriend(objToClaim?.isPremium, objToClaim?.point)
        }}
        point={objToClaim?.point}
        isPremium={objToClaim?.isPremium}
        />
      }
      <FriendWrapper>
        <div>
          <FriendListTop>
            {listInvite.map((item: any, index: number) => (
              <li key={index}>
                <div className="icon-decor">
                  <img src={item.icon} alt="" />
                </div>
                <div className="content-left">
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                </div>
                <div className="content-right">
                  {
                    listPointOfInviteFriends && listPointOfInviteFriends[index] !== 0 && 
                    <>
                      <button className="button-claim"
                        onClick={() => {
                          setObjToClaim({
                            isPremium: index !== 0,
                            point: listPointOfInviteFriends[index]
                          })
                          setIsShowClaimInviteFr(true)
                        }}
                      >
                      Claim
                    </button>
                    </> 
                  }
                </div>
                <div className="image-coin">
                  <img src="/images/Friends/icn_coin.png" alt="Coin" />
                </div>
              </li>
            ))}
          </FriendListTop>
          <BlockInvitedFriend>
            <BlockInvitedFriendInner>
              <Contentleaderboard>
                <ContentleaderboardTitle>
                  <div className="title-left">
                    <p className="text-rank">Your Friends</p>
                  </div>
                  <div className="content-right">
                    <span className="number">{listFriends ? listFriends.total : 0}</span>
                    <img className='user' src="/images/Friends/icn_user_dummy.png" alt="Rank" />
                  </div>
                </ContentleaderboardTitle>
                <ListleaderboardContent>
                  <Listleaderboard onScroll={handleLoadMore}>
                    {listFriends &&
                      listFriends?.data?.map((item: any, index: number) => (
                      <li key={index}>
                        <div className="content-position">
                          <div className="position-left">
                            <img src='/static/icon-fr.png' alt="" />
                          </div>
                          <p className="name-user">{item.fullname}</p>
                        </div>
                        <div className="content-user">
                          <span>{item.level_info}</span>
                        </div>
                      </li>
                    ))}
                  </Listleaderboard>
                  <MyRank>
                    <p className="text-invite">Invite friend now</p>
                    <BlockBtnInvitedFriend>
                      <button className="button-invite" onClick={() => {
                        handleInviteFriend('invite');
                        }}>
                          Invite
                      </button>
                      <ButtonInvite
                        onClick={() => {
                          handleInviteFriend('copy');
                        }}
                      >
                        <CopyOutlined />
                      </ButtonInvite>
                    </BlockBtnInvitedFriend>
                  </MyRank>
                </ListleaderboardContent>
              </Contentleaderboard>
            </BlockInvitedFriendInner>
          </BlockInvitedFriend>
        </div>
      </FriendWrapper>
    </FriendContainer>
  );
};

const listInvite = [
  {
    icon: '/images/Friends/icn_tele.png',
    title: 'INVITE FRIENDS',
    text: 'Invite more friends & receive 10,000 $sKIB',
  },
  {
    icon: '/images/Friends/icn_premium.png',
    title: 'INVITE BY PREMIUM',
    text: 'Invite more Premium friends & receive 25,000 $sKIB',
  },
];

const listTop = [
  {
    img: '/images/Friends/icn_user_dummy.png',
    name: '@USERNAME',
    point: 'Lv: 05',
  },
  {
    img: '/images/Friends/icn_user_dummy.png',
    name: '@USERNAME',
    point: 'Lv: 05',
  },
  {
    img: '/images/Friends/icn_user_dummy.png',
    name: '@USERNAME',
    point: 'Lv: 05',
  },
  {
    img: '/images/Friends/icn_user_dummy.png',
    name: '@USERNAME',
    point: 'Lv: 05',
  },
  {
    img: '/images/Friends/icn_user_dummy.png',
    name: '@USERNAME',
    point: 'Lv: 05',
  },
];

export default FriendsV2;
