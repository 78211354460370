import { styled } from "styled-components";
import { ButtonCommonStyle } from "../../components/Common/Button/styled";

export const BoardPageStyle = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
  .img-header-board {
    max-width: 100%;
    height: calc(100vh - 65vh);
    height: calc(100svh - 65svh);
    /* position: fixed; */
    /* top: 0px;
    left: 0; */
    z-index: 101;
    width: 100%;
    img {
      width: 100%;
    }
  }
`;

export const BoardPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  padding: 0 15px;
  margin-top: calc(100vh - 600px);
`;

export const ClickBanner = styled.div`
  position: relative;
  width: 100%;
  height: 190px;
  background: url("/static/bg-go-to-click.png") center / 100% auto no-repeat;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  .img-title {
    max-width: 160px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  p {
    color: #fff;
  }
  .text-status {
    position: absolute;
    z-index: 2;
    top: 15px;
    left: 7px;
    color: #fff;
    text-align: center;
    font-family: "Berlin Sans FB Demi";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 12px */
    transform: rotate(-45deg);
  }
  ${ButtonCommonStyle} {
    position: relative;
    z-index: 1;
  }
`;

export const PredictionBanner = styled.div`
  position: relative;
  width: 100%;
  height: 190px;
  background: url("/static/bg-go-to-predic.png") center / 100% auto no-repeat;
  .img-title {
    max-width: 180px;
    margin-top: 15px;
    img {
      width: 100%;
    }
  }
  p {
    color: #3a37f5;
  }
  .text-status {
    position: absolute;
    z-index: 2;
    top: 17px;
    left: 2px;
    color: #fff;
    text-align: center;
    font-family: "Berlin Sans FB Demi";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 12px */
    transform: rotate(-45deg);
  }
`;

export const WrapContentBanner = styled.div`
  max-width: 190px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 25px;
  left: 25px;
  p {
    text-align: center;
    font-family: "Berlin Sans FB Demi";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 12px */
    margin-bottom: 6px;
  }
`;
