import styled from 'styled-components';
import bg from '../../assets/Game/game_bg.webp';
import layer_intro from '../../assets/Game/layer_info.webp';
import coin_intro_decor from '../../assets/Game/coin_intro_decor.webp';
import game_block from '../../assets/Game/game_block_bg.webp';
import progress_icon from '../../assets/Game/progress_icon.webp';
import icn_point_progress from '../../assets/Game/icn_point_progress.webp';
import icnBgGuide from '../../assets/Game/icn_bg_guide.webp';

export const GameContainer = styled.div`
  position: relative;
`;

export const GamePlaying = styled.div`
  position: absolute;
  top: 75px;
  left: 10px;
  width: 110px;
  img {
    max-width: 100%;
  }
`;

export const GameWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
`;
export const GameIntro = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 629px;
    height: 896px;
    margin-left: -315px;
    background: url(${layer_intro}) no-repeat center / 100% auto;
  }
`;
export const GameIntroImage = styled.div`
  position: relative;
  width: 309px;
  height: 315px;
  margin: 0 auto;
  img {
    width: 100%;
  }
  &::before {
    content: '';
    position: absolute;
    margin-left: -180px;
    left: 50%;
    top: -40px;
    background: url(${coin_intro_decor}) no-repeat center / 100% auto;
    width: 388px;
    height: 314px;
  }
`;
export const GameIntroText = styled.div`
  position: relative;
  padding: 0 20px;
  & > h2 {
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'ErasBoldITC';
    margin-bottom: 15px;
  }
  & > p {
    font-size: 15px;
    text-align: center;
    color: #fff;
    font-family: 'iCiel';
    line-height: 20px;
    span {
      color: #1eb0ff;
    }
  }
`;
export const GameHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 70px;
  z-index: 10;
  & > figure {
    position: relative;
    width: 42px;
    height: 43px;
    img {
      width: 100%;
    }
  }
  & > p {
    font-size: 20px;
    font-family: 'ErasBoldITC';
    font-size: 36px;
    color: #fff;
    text-transform: lowercase;
  }
`;
export const CoinBaseImage = styled.img<{ count?: any }>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -21px;
  margin-top: -22px;
  width: 42px;
  height: 43px;
  opacity: 0;
  animation: flowCoinBase 1s cubic-bezier(0.23, 1, 0.32, 1);
  @keyframes flowCoinBase {
    0% {
      transform: translateX(0) translateY(300%);
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateX(0) translateY(0);
    }
  }
`;
export const PointAppear = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  .img-count {
    pointer-events: none;
    max-width: 40px;
    animation: flowPoint 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    /* position: absolute;
    z-index: 10;
    opacity: 0; */
    h1 {
      color: #fff;
      text-align: center;
      text-shadow: 0px 3.593px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #ff6b04;
      font-family: 'Luckiest Guy', cursive;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 62.884px */
    }
  }
  &.is-click {
    pointer-events: auto;
  }
  @keyframes flowPoint {
    0% {
      transform: 0;
    }
    10% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    70% {
      opacity: 0.4;
    }
    100% {
      transform: translateY(-300%);
    }
  }
`;
export const GameCoin = styled.div`
  position: relative;
  width: 290px;
  height: 290px;
  margin: 50px auto 50px;
  margin-top: 0;
  z-index: 10;
  ${PointAppear} {
    img {
      width: 100%;
    }
  }
`;

export const GameCoinWrap = styled.div`
  overflow: auto;
  height: 800px;
  width: 100%;
  margin: 0 auto;
`;

export const GameProgressText = styled.div`
  font-family: 'ErasBoldITC';
  font-size: 15px;
  text-transform: lowercase;
  color: #fff;
  margin-left: -3px;
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffb800;
  font-size: 17px;
  font-style: normal;
  line-height: 1;
  opacity: 0;
  transition: opacity 0.26s ease;
  &:before {
    background: url(${icn_point_progress}) center / cover no-repeat;
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin-top: -44px;
    margin-left: -44px;
    width: 89px;
    height: 89px;
    z-index: -1;
  }
`;
export const GameProgress = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -70px;
  margin-top: -20px;
  display: flex;
  flex-direction: row-reverse;
  z-index: 10;
  width: 0;
  opacity: 0;
  transition: width 0.26s ease;
  &::before {
    content: '';
    position: absolute;
    top: -26px;
    left: -64px;
    pointer-events: none;
    background: url(${progress_icon}) no-repeat center / 100% auto;
    width: 103px;
    height: 102px;
    margin-top: -18px;
    opacity: 0;
    transition: opacity 0.26s ease;
  }
  &.is-show {
    width: 100%;
    opacity: 1;
    &:before {
      opacity: 1;
    }
    ${GameProgressText} {
      opacity: 1;
    }
  }
`;

export const GameProgressBar = styled.div<{ Progress: number }>`
  width: 100%;
  height: 13px;
  border: 2px solid #ffb800;
  background: #282828;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  span {
    display: block;
    height: 13px;
    width: ${({ Progress }) => (Progress > 0 ? Progress : 0)}%;
    background: linear-gradient(90deg, #6f3e04 0%, #ffb800 100%);
    border-radius: ${({ Progress }) => (Progress === 100 ? '0' : '20px')};
    transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
`;
export const GameTools = styled.div`
  position: relative;
  max-width: 350px;
  top: 0px;
  margin: 0 auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 0;
  gap: 10px;
`;
export const GameBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 40%;
  padding: 7px 0 5px;
  margin-top: -17px;
  margin-left: auto;
  /* background: url(${game_block}) no-repeat center / 100% 100%; */
  position: relative;
  z-index: 1;
  &:before {
    border-radius: 10px;
    background-color: #2a3970;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: skewX(-8deg);
  }
  & > div {
    width: 100%;
    text-align: center;
    & > p {
      color: #fff;
      text-align: center;
      text-shadow: 0px 1.953px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #ff5e03;
      font-family: 'ErasBoldITC';
      font-size: 14px;
      line-height: 1;
    }
  }
  &:nth-child(1) {
    ${GameProgressBar} {
      border-color: #34f497;
      span {
        background: linear-gradient(90deg, #282828 0%, #0ff586 100%);
      }
    }
  }
  &:nth-child(2) {
    ${GameProgressBar} {
      border-color: #db4c4a;
      span {
        background: linear-gradient(90deg, #db4843 0%, #ffff69 100%);
      }
    }
  }
  figure {
    position: relative;
    top: 0px;
    width: 14px;
    height: 17px;
    flex-shrink: 0;
    /* left: 23px; */
    img {
      position: relative;
      z-index: 10;
      width: 100%;
    }
  }
  ${GameProgressBar} {
    width: 100%;
    z-index: 10;
  }
`;

export const BlockTime = styled.div`
  position: relative;
  .time-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    -webkit-text-stroke-width: 0.9333948493003845;
    -webkit-text-stroke-color: #6590ff;
    font-family: 'ErasBoldITC';
    font-size: 11.667px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    right: 14px;
    bottom: 9px;
  }
`;
export const LeaderBoard = styled.div`
  text-align: center;
  position: relative;
  z-index: 1;
  width: 62px;
  margin-left: auto;
  margin-top: 15px;
  margin-right: 15px;
  span {
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ff5b02;
    font-family: 'ErasBoldITC';
    font-size: 12px;
  }
`;
export const BlockGuide = styled.div`
  color: #fff;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    max-width: 87px;
    width: 100%;
  }
  .guide-inner {
    max-width: 304px;
    width: 100%;
    background: url(${icnBgGuide}) center / 100% auto no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 17px 10px 30px;
    p {
      color: var(--Blue--Pri-700, #007af5);
      font-family: 'Franklin';
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%; /* 18.75px */
    }
    span {
      color: var(--trionn-com-woodsmoke, #121315);
      font-family: 'ErasBoldITC';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      display: inline-block;
    }
    button {
      border-radius: 9px;
      border: 2px solid #1000c3;
      background: linear-gradient(180deg, #7aafff 0%, #236ee0 100%);
      box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.25) inset;
      padding: 9px 10px;
      color: #fff;
      text-align: center;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #3c72ff;
      font-family: 'ErasBoldITC';
      font-size: 14px;
      font-style: normal;
    }
  }
`;
