import styled from 'styled-components';

export const AppContainer = styled.div<{ bgUrl?: any }>`
  position: relative;
  color: #fff;
  z-index: 1;
  min-height: 100vh;
  min-height: 100svh;
  overflow: hidden;
  /* &:after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background-color: #2c1602;
    z-index: -1;
  } */
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    height: 100svh;
    pointer-events: none;
    content: '';
    background: #2c1602 url(${({ bgUrl }) => (bgUrl ? bgUrl : '/static/bg-main.png')});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
  &.show-overlay {
    &:after {
      opacity: 1;
    }
  }
`;
