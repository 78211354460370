import { AppContainer } from "./styled";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import "./App.css";
import {
  Navigate,
  Route,
  Router,
  Routes,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useContext, useEffect, useMemo, useState } from "react";
import LayoutDashboard from "./Layout/Dashboard";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import WebApp from "@twa-dev/sdk";
import { ContextProvider, ContextProviderWrapper } from "./components/Context";
import {
  createNavigator,
  useNavigatorIntegration,
} from "@tma.js/react-router-integration";
import "animate.css";
import ContentPage from "./page/Contents";
import FriendsV2 from "./page/Friends/indexV2";
import AssetsNew from "./page/AssetsNew";
import LeaderboardV2 from "./page/Leaderboard/indexV2";
import EarnV2 from "./page/Earn/indexv2";
import ClickerV2 from "./page/Clicker/indexv2";
import eruda from "eruda";
import BoardPage from "./page/Board";
import LoadingV2 from "./page/LoadingV2";
import { useInitData } from "@tma.js/sdk-react";
// eruda.init()

const App = () => {
  const tmaNavigator = useMemo(createNavigator, []);
  const [location, navigator] = useNavigatorIntegration(tmaNavigator);
  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;

  const settingDefaultMiniApp = () => {
    WebApp.isClosingConfirmationEnabled = true;
    WebApp.expand();
    WebApp.ready();
  };

  useEffect(() => {
    settingDefaultMiniApp();
  }, []);

  useEffect(() => {
    settingDefaultMiniApp();
  }, []);


  return (
    <Router location={location} navigator={navigator}>
      <ContextProvider>
        <Provider store={store}>
          <TonConnectUIProvider manifestUrl="https://app.kibble.exchange/static/tonconnect-manifest.json">
            <Toaster position="top-right" reverseOrder={false} />
            <AppContainer>
              <Routes>
                <Route element={<LayoutDashboard />}>
                  <Route path="/" element={<LoadingV2 />} />
                  <Route path="/clicker" element={<ClickerV2 />} />
                  <Route path="/games" element={<BoardPage />} />
                  <Route path="/assets" element={<AssetsNew />} />
                  <Route path="/earn" element={<EarnV2 />} />
                  <Route path="/friends" element={<FriendsV2 />} />
                  <Route path="/leaderboard" element={<LeaderboardV2 />} />
                  <Route path="/content" element={<ContentPage />} />
                </Route>
                <Route path="*" element={<Navigate to="/games" />} />
              </Routes>
            </AppContainer>
          </TonConnectUIProvider>
        </Provider>
      </ContextProvider>
    </Router>
  );
};

export default App;
