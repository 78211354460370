import { styled } from 'styled-components';

export const ContentContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 100px;
  min-height: calc(100vh - 190px);
`;

export const ContentWrap = styled.div`
  max-width: 350px;
  margin: 0 auto;
`;

export const ContentHeader = styled.div`
  position: relative;
  .img-header {
    max-width: 350px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  h1 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 3.593px 0px rgba(0, 0, 0, 0.25);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ff6b04;
    font-family: 'Luckiest Guy', cursive;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 62.884px */
  }
`;

export const ContentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -10px;
  .img-social {
    max-width: 60px;
    img {
      width: 100%;
    }
  }
  h2 {
    color: #fff;
    text-align: center;
    font-family: 'ErasBoldITC';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.64px;
    text-transform: uppercase;
    max-width: 320px;
    margin-top: 5px;
  }
`;

export const ContentInput = styled.div`
  margin-top: 10px;
  p {
    color: #fff;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    font-family: 'ErasBoldITC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.56px;
    text-transform: uppercase;
  }
  .input-url {
    display: flex;
    height: 45px;
    margin: 5px 0;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #ffc72e;
    background: #fff;
    box-shadow: 0px -6px 0px 0px rgba(0, 0, 0, 0.25) inset;
    padding: 0 5px;
    padding-left: 12px;
    input {
      width: 100%;
      border: none;
      outline: none;
      color: #4e4e4e;
      text-overflow: ellipsis;
      font-family: 'ErasBoldITC';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.56px;
    }
  }
  button {
    display: flex;
    height: 40px;
    width: 100%;
    padding: var(--Click, 17px) var(--Click, 0px) var(--Click, 18px)
      var(--Click, 0px);
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 9px;
    border: 1.509px solid #fff;
    background: linear-gradient(180deg, #ffd902 0%, #ff5602 100%);
    box-shadow: 0px -5px 0px 0px #fd3801 inset;
    span {
      font-size: 18px;
    }
  }
`;

export const ContentSteps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 20px;
  .title {
    display: flex;
    padding: var(--Click, 6px) var(--Click, 10px) var(--Click, 5px)
      var(--Click, 8px);
    justify-content: center;
    align-items: center;
    border-radius: 35.613px;
    border: 1.548px solid #e83701;
    background: #fe7520;
    p {
      color: #fff;
      text-align: center;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      font-family: 'ErasBoldITC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      letter-spacing: -0.56px;
      text-transform: uppercase;
    }
    .img-check {
      max-width: 16px;
      img {
        width: 100%;
      }
    }
  }
  .no-active {
    h2 {
      color: #fff;
      text-align: center;
      font-family: 'ErasBoldITC';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 17.6px */
      letter-spacing: -0.64px;
      text-transform: uppercase;
      max-width: 320px;
      margin-top: 5px;
    }
  }

  .active-status {
    h2 {
      color: #ff6200;
      text-align: center;
      font-family: 'ErasBoldITC';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 17.6px */
      letter-spacing: -0.64px;
      text-transform: uppercase;
      max-width: 320px;
      margin-top: 5px;
    }
    .title {
      padding: 6px;
      padding-bottom: 3px;
    }
  }
`;

export const ContentStep = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
