import { useContext, useEffect, useState } from 'react';
import { useDispatch } from '../../../redux/store';
import { ModalContainer } from '../../Assets/Modal/modal';
import {
  AllDailyInWeek,
  DailyInWeek,
  ModalInviteRewarWrapContent,
  ModalInviteRewardContainer,
} from './styleModalDailyv2';
import { KIBBLE_API } from '../../../services/api';
import { getDailyMess, getDataInfoUser, getToastMess } from '../../../redux/features/pointSlice';
import { convertFormatNumber } from '../../../utils/convertFormatNumber';
import { useInitData } from '@tma.js/sdk-react';
import ButtonCommonV2 from '../../../components/Common/Button';
import { BlockBtnInvitedFriend } from '../../Friends/styled';

type ModalDailyType = {
  onClose: () => void;
  point?: any;
  onSubmit?: () => void;
  isPremium?: boolean
};

export default function ModalInviteReward({
  onClose,
  onSubmit,
  point,
  isPremium,
}: ModalDailyType) {

  return (
    <ModalContainer>
      <ModalInviteRewardContainer>
        <div className="img-close" onClick={onClose}>
          <img src="/static/img-close.png" alt="close" />
        </div>
        <div className="img-title">
          <img src="/static/img-title-modal-invite.png" alt="title" />
        </div>
        <h1>
          {
            isPremium ? 'INVITE BY PREMIUM' : 'INVITE FRIENDS'
          }
        </h1>
        <h2>Congratulations</h2>
        <ModalInviteRewarWrapContent>
          <div className="img-bg-reward">
            <img src="/static/bg-reward.png" alt="" />
          </div>
          <h3>Your Rewards</h3>
          <div className="point-claim">
            <div className="img-coin">
              <img src="/static/icon-coin.png" alt="" />
            </div>
            <span>{point}</span>
          </div>
        </ModalInviteRewarWrapContent>
        <ButtonCommonV2 text="CLAIM NOW" onClick={onSubmit} />
      </ModalInviteRewardContainer>
    </ModalContainer>
  );
}
