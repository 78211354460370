import {
  ButtonTabPartner,
  GameContainer,
  IconDailyBonus,
  ListTask,
  MysteryTask,
  MysteryTaskItem,
  MysteryTaskRandom,
  MysteryTaskRandomPoint,
  MysteryTaskRandomTitle,
  MysteryTaskTitle,
} from './styledv2';
import earnDailyImg from '../../assets/Game/Earn/earn_daily_01.png';
import { useContext, useEffect, useState } from 'react';
import ModalDaily from './Modal/modalDaily';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useInitData } from '@tma.js/sdk-react';
import { useDispatch } from '../../redux/store';
import { KIBBLE_API } from '../../services/api';
import { getDataInfoUser, getDataListTask, getToastMess } from '../../redux/features/pointSlice';
import { checkUserInGroupChat } from '../../utils/checkUserTaskTele';
import { convertFormatNumber } from '../../utils/convertFormatNumber';
import _ from 'lodash';
import WebApp from '@twa-dev/sdk';
import { AssetsContentTop } from '../AssetsNew/styled';

const EarnV2 = () => {
  
  const [modalClaimDaily, setModalClaimDaily] = useState(false);
  const [listTaskClicked, setListTaskClicked] = useState<any>([]);
  const [listTaskActive, setListTaskActive] = useState<any>([]);

  const [infoCheckin, setInfoCheckin] = useState(null);
  const [isJoinTele, setIsJoinTele] = useState(false);
  const [taskPartner, setTaskPartner] = useState('user');
  
  const { listTask, dailyMessage, myterytask } = useSelector(
    (state: any) => state.point
  );
  const navigate = useNavigate();

  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;

  const dispatch = useDispatch();

  const handleExcuteActionJoinTele = async (mission: any) => {
    try {
      if(mission?.status === 'CLAIM' && isJoinTele) {
        await KIBBLE_API.getTaskClaim({
          id: mission?.task_id,
        });
        dispatch(
          getToastMess({
            message: `Got +${convertFormatNumber(Number(mission?.point))?.replaceAll(".00", "")} points`,
            status: true,
          })
        );
        dispatch(getDataListTask());
        dispatch(getDataInfoUser(user));
      } else {
        handleOpenLinkToCompleteTask(mission)
      }
    } catch (error) {
      console.log('====================================');
      console.log('handleExcuteActionJoinTele err', error);
      console.log('====================================');
    }
  }

  const handleExcuteAction = async (mission: any) => {
    try {
      if (mission.is_content_creation && mission?.status === 'OPEN') {
        await localStorage.setItem('taskContentId', mission?.task_id);
        navigate('/content');
      } else if (mission?.status === 'CLAIM') {
        await KIBBLE_API.getTaskClaim({
          id: mission?.task_id,
        });
        dispatch(
          getToastMess({
            message: `Got +${convertFormatNumber(Number(mission?.point))?.replaceAll(".00", "")} points`,
            status: true,
          })
        );
        dispatch(getDataListTask());
        dispatch(getDataInfoUser(user));
      } else if (mission?.status === 'OPEN') {
        handleOpenLinkToCompleteTask(mission)
      }
    } catch (error) {
      console.log('====================================');
      console.log('handleExcuteAction err', error);
      console.log('====================================');
    }
  };

  const handleOpenLinkToCompleteTask = async (mission:any) => {
    try {
      const isLinkKibbleApp = mission?.url?.search(
        'https://t.me/KibbleExchangeBot'
      );
      if (isLinkKibbleApp !== -1) {
        const listClicked = [...listTaskClicked]
        listClicked.push({
          id: mission?.task_id
        })
        setListTaskClicked(listClicked)
        await KIBBLE_API.submitTaskComplete({
          id: mission?.task_id,
          is_content_creation: mission.is_content_creation,
        });
        WebApp.openTelegramLink(mission.url);
        setTimeout(() => {
          dispatch(getDataListTask());
          dispatch(getDataInfoUser(user));
          const listClicked = [...listTaskClicked]
          listClicked.filter((item:any) => item.id !== mission?.task_id)
          setListTaskClicked(listClicked)
        }, 15000);
      } else {
        WebApp.showConfirm(
          `Open this link: ${mission.url}?`,
          async (comfirmed) => {
            if (comfirmed) {
              try {
                const listClicked = [...listTaskClicked]
                listClicked.push({
                  id: mission?.task_id
                })
                setListTaskClicked(listClicked)
                await KIBBLE_API.submitTaskComplete({
                  id: mission?.task_id,
                  is_content_creation: mission.is_content_creation,
                });
                WebApp.openLink(mission.url);
                setTimeout(() => {
                  dispatch(getDataListTask());
                  dispatch(getDataInfoUser(user));
                  const listClicked = [...listTaskClicked]
                  listClicked.filter((item:any) => item.id !== mission?.task_id)
                  setListTaskClicked(listClicked)
                }, 15000);
              } catch (error) {
                dispatch(
                  getToastMess({
                    message: 'Try again, please',
                    status: false,
                  })
                );
              }
            }
          }
        );
      }
    } catch (error) {
      console.log('====================================');
      console.log('handleOpenNewWindow err', error);
      console.log('====================================');
      dispatch(
        getToastMess({
          message: 'Try again, please',
          status: false,
        })
      );
    }
  };

  const handleGetInfoCheckin = async () => {
    try {
      const { data } = await KIBBLE_API.getInfoCheckin();
      setInfoCheckin(data);
      const isJoinTeleChat = await checkUserInGroupChat(user.id);
      dispatch(getDataListTask());
      setIsJoinTele(isJoinTeleChat?.ok);
    } catch (error) {
      console.log('====================================');
      console.log('handleGetInfoCheckin err', error);
      console.log('====================================');
    }
  };

  const handleSelectTypeTask = (type: string) => {
    setTaskPartner(type)
    let listTaskUser = []
    if(type === 'user') {
      listTaskUser = listTask?.filter((item:any) => item && item.is_normal)
    } else {
      listTaskUser = listTask?.filter((item:any) => item && !item.is_normal)
    }
    setListTaskActive(listTaskUser)
  }

  useEffect(() => {
    if (listTask.length === 0) {
      dispatch(getDataListTask());
    }
    handleGetInfoCheckin();
  }, []);

  useEffect(() => {
    if (dailyMessage) {
      handleGetInfoCheckin();
    }
  }, [dailyMessage]);

  useEffect(() => {
    if(listTask?.lenght === 0) return
    let listTaskUser = []
    if(taskPartner === 'user') {
      listTaskUser = listTask?.filter((item:any) => item && item.is_normal)
    } else {
      listTaskUser = listTask?.filter((item:any) => item && !item.is_normal)
    }
    setListTaskActive(listTaskUser)
  }, [listTask])

  return (
    <GameContainer className='animate__animated animate__fadeIn'>
      {infoCheckin && modalClaimDaily && (
        <ModalDaily
          onClose={() => {
            setModalClaimDaily(false);
          }}
          infoDailyChecking={infoCheckin}
        />
      )}
      <MysteryTask>
        <MysteryTaskItem
          onClick={() => {
            myterytask?.name && handleExcuteAction(myterytask);
          }}
        >
          <MysteryTaskTitle>
            <div className="img-random">
              <img src="/static/img-random.png" alt="" />
            </div>
            <h4>Mystery task</h4>
          </MysteryTaskTitle>
          <MysteryTaskRandom>
            <MysteryTaskRandomTitle>
              <div className="img-k">
                <img 
                  src={
                  myterytask?.thumbnail_info
                    ? myterytask?.thumbnail_info?.file_path
                    : '/static/icon-k.png'
                } alt="icon" />
              </div>
              <div className="gr-title">
                <h4>
                  {myterytask?.name
                    ? myterytask?.name
                    : 'Coming soon...'}
                </h4>
                <p>
                  {myterytask?.description
                    ? myterytask?.description
                : 'Task description...'}</p>
              </div>
            </MysteryTaskRandomTitle>
            <MysteryTaskRandomPoint>
              <div className="icon-all-coin">
                <img src="/static/icon-all-coin.png" alt="coin" />
              </div>
              {myterytask?.point && (
                <h2>
                  {convertFormatNumber(
                    Number(myterytask?.point)
                  )?.replaceAll('.00', '')}
                </h2>
              )}
            </MysteryTaskRandomPoint>
              {
                myterytask?.status === 'CLAIM' ? 
                  <div className="img-check">
                    <img src="/static/icon-check.png" alt="check" />
                  </div>
                  : myterytask?.status === 'PENDING' ? 
                  <div className="img-check">
                    <img src="/static/icon-lock.png" alt="lock" />
                  </div> :
                  listTaskClicked.length > 0 && listTaskClicked.find((task:any) => task.id === myterytask?.task_id) ?
                  <div className="img-check">
                    <img src="/static/img-loading.png" className='animate-rotate' alt="lock" />
                  </div> 
                  : <></>
              }
          </MysteryTaskRandom>
        </MysteryTaskItem>
        <IconDailyBonus
          onClick={() => {
            setModalClaimDaily(true);
          }}
        >
          <div className="img-daily">
            <img src="/static/icon-daily-bonus.png" alt="" />
          </div>
        </IconDailyBonus>
      </MysteryTask>
      <AssetsContentTop>
        <>
          <ButtonTabPartner 
            onClick={() => {
              handleSelectTypeTask('user')
            }}
            className={
              taskPartner === 'user' ? 'tab-active' : ''
            }
          >
            <p>Mission</p>
          </ButtonTabPartner>
          <ButtonTabPartner 
            onClick={() => {
              handleSelectTypeTask('partner')
            }}
            className={
              taskPartner === 'partner' ? 'tab-active' : ''
            }
          >
            <p>Partner Quest</p>
          </ButtonTabPartner>
        </>
      </AssetsContentTop>
      <ListTask>
        {listTaskActive && listTaskActive.length > 0 ? listTaskActive.map((item: any, index: number) => {
          return (
            <MysteryTaskRandom
              key={index}
              className={item?.status === 'PENDING' || item?.status === 'CLAIM' ? 'task-complete' : 'task-un-complete'}
              onClick={() => {
                item?.task_type !== 'JOIN_CHAT' ?
                 handleExcuteAction(item) 
                 : handleExcuteActionJoinTele(item);
              }}
            >
              <MysteryTaskRandomTitle>
                <div className="img-k">
                  <img 
                  src={
                    item?.thumbnail_info
                      ? item?.thumbnail_info?.file_path
                      : '/static/icon-k.png'
                  } alt="icon" />
                </div>
                <div className="gr-title">
                  <h4>
                  {_.truncate(item?.name, {
                      length: 30,
                    })}
                  </h4>
                  <p>{item?.description}</p>
                </div>
              </MysteryTaskRandomTitle>
              <MysteryTaskRandomPoint>
                <div className="icon-all-coin">
                  <img src="/static/icon-all-coin.png" alt="coin" />
                </div>
                <h2>
                  {item?.status !== 'PENDING' &&
                    convertFormatNumber(
                      Number(item?.point)
                    )?.replaceAll('.00', '')}
                </h2>
              </MysteryTaskRandomPoint>
              {item?.status === 'CLAIM' ? 
                <div className="img-check">
                  <img src="/static/icon-check.png" alt="check" />
                </div>
                : item?.status === 'PENDING' ? 
                <div className="img-check">
                  <img src="/static/icon-lock.png" alt="lock" />
                </div> :
                 listTaskClicked.length > 0 && listTaskClicked.find((task:any) => task.id === item?.task_id) ?
                 <div className="img-check">
                   <img src="/static/img-loading.png" className='animate-rotate' alt="lock" />
                 </div> 
                : <></>
              }
            </MysteryTaskRandom>
          );
        }):
        (
          <li
            className="all-task-claim"
            style={{
              padding: '20px 15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div className="content-left">
              <div className="title">
                <h4
                  style={{
                    textAlign: 'center',
                  }}
                >
                  You Have Completed <br></br>
                  All Tasks
                </h4>
              </div>
            </div>
          </li>
        )
        }
      </ListTask>
    </GameContainer>
  );
};

const listDailyDetail = [
  {
    img: earnDailyImg,
    title: 'FOLLOW  X',
    point: '500',
  },
  {
    img: earnDailyImg,
    title: 'FOLLOW  X',
    point: '500',
  },
  {
    img: earnDailyImg,
    title: 'FOLLOW  X',
    point: '500',
  },
  {
    img: earnDailyImg,
    title: 'FOLLOW  X',
    point: '500',
  },
];

const listDaily = [
  {
    day: '1',
    point: '20,000',
  },
  {
    day: '2',
    point: '20,000',
  },
  {
    day: '3',
    point: '20,000',
  },
  {
    day: '4',
    point: '20,000',
  },
  {
    day: '5',
    point: '20,000',
  },
];

export default EarnV2;
