import React, { useState } from 'react';
import {
  ButtonDisconnect,
  WalletAddress,
  WalletContainer,
  WalletContent,
  WalletWrapper,
} from './styled';
import { Modal } from 'antd';
import { shortenAddress } from '../../../utils/addressUser';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { CopyOutlined } from '@ant-design/icons';
import { useTonConnectUI } from '@tonconnect/ui-react';

const WalletModalInfo = ({ open, cancel, title, address }: any) => {
  const [tonConnectUI] = useTonConnectUI();

  const handleDisconnect = async (e: any) => {
    e.stopPropagation();
    try {
      cancel();
      await tonConnectUI.disconnect();
    } catch (error) {
      console.error('error', error);
    }
  };
  return (
    <WalletContainer>
      <WalletWrapper>
        <Modal title={title} open={open} onCancel={cancel} footer={false}>
          <WalletContent>
            <WalletAddress>
              <div>
                <h1>
                  Address:<span>{shortenAddress(address)}</span>
                </h1>
              </div>
              <CopyToClipboard
                text={address}
                onCopy={() => {
                  toast.success('Copied !!');
                }}
              >
                <div
                  style={{
                    marginRight: '14px',
                    color: '#92929E',
                    cursor: 'pointer',
                  }}
                >
                  <span>Copy</span>
                  <CopyOutlined />
                </div>
              </CopyToClipboard>
            </WalletAddress>
            <ButtonDisconnect onClick={handleDisconnect}>
              Disconnect
            </ButtonDisconnect>
          </WalletContent>
        </Modal>
      </WalletWrapper>
    </WalletContainer>
  );
};

export default WalletModalInfo;
