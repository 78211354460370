import axios from 'axios';

export const getTokenInWallet = async (address: string) => {
  try {
    const rawAddress = await convertAddressWalletToRaw(address);
    const { data } = await axios.get(
      `${process.env.REACT_APP_TON_CONSOLE_URL}/accounts/${rawAddress}/jettons`
    );
    const tonToken = await getTonBalance(address);
    const listTokenHasTon = [...data?.balances];
    listTokenHasTon.unshift(tonToken);
    if (listTokenHasTon.length === 0) {
      return [];
    }
    const updatedDisplayAssets = listTokenHasTon
      .map((item: any) => {
        return {
          decimals: Number(item.jetton ? item.jetton?.decimals : 9),
          display_name: item.jetton ? item.jetton?.name : 'TON',
          symbol: item.jetton ? item.jetton?.symbol : 'TON',
          contract_address: item.jetton
            ? item.jetton?.address.__root__
            : process.env.REACT_APP_TON_ADDRESS,
          balance: item.balance,
          image: item.jetton ? item.jetton?.image : '/static/ton.png',
        };
      })
      .sort((a: any, b: any) => b.balance - a.balance);
    return updatedDisplayAssets;
  } catch (error) {
    return [];
  }
};

export const getTonBalance = async (address: string) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_TON_CONSOLE_URL}/accounts/${address}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

const convertAddressWalletToRaw = async (address: string) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_TON_CONSOLE_URL}/address/${address}/parse`
    );
    return data.raw_form;
  } catch (error) {
    return address;
  }
};
