import { styled } from 'styled-components';

export const ModalDailyContainer = styled.div`
  position: relative;
  width: 350px;
  margin: 0 auto;
  padding: 30px 0;
  flex-shrink: 0;
  border-radius: 19px;
  border: 5.929px solid #c8507e;
  background: linear-gradient(180deg, #aa3f66 0%, #733577 100%);
  h1 {
    color: #fff;
    text-align: center;
    font-family: 'Eras Bold ITC';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.64px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .img-title {
    max-width: 280px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -45px;
    left: 40px;
    img {
      width: 100%;
    }
  }
  .img-close {
    max-width: 25px;
    position: absolute;
    right: -5px;
    top: -15px;
    z-index: 99;
    img {
      width: 100%;
    }
  }
`;

export const ModalInviteRewardContainer = styled.div`
  position: relative;
  width: 350px;
  margin: 0 auto;
  padding: 30px 0;
  flex-shrink: 0;
  border-radius: 19px;
  border: 5.929px solid #c8507e;
  background: linear-gradient(180deg, #aa3f66 0%, #733577 100%);
  padding: 20px;
  h1 {
    color: var(--Neutral-50, #F7F7F8);
    -webkit-text-stroke-width: 0.9333948493003845;
    -webkit-text-stroke-color: #E27A00;
    font-family: "Berlin Sans FB Demi";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 21.25px */
    letter-spacing: 0.17px;
    text-align: center;
    margin-top: 20px;
  }
  h2 {
    color: #FFF;
    text-align: center;
    font-family: "Berlin Sans FB Demi";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 19.8px */
    letter-spacing: -0.36px;
  }
  .img-title {
    max-width: 280px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -45px;
    left: 50px;
    img {
      width: 100%;
    }
  }
  .img-close {
    max-width: 25px;
    position: absolute;
    right: 5px;
    top: -15px;
    z-index: 99;
    img {
      width: 100%;
    }
  }
`;

export const ModalInviteRewarWrapContent = styled.div`
  margin-top: 40px;
  margin-bottom: 35px;
  .img-bg-reward {
    max-width: calc(100% - 40px);
    margin: 0 auto;
    left: 20px;
    top: 100px;
    position: absolute;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  h3 {
    color: var(--trionn-com-woodsmoke, #121315);
    text-align: center;
    font-family: "Berlin Sans FB Demi";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 15.098px */
    position: relative;
    z-index: 2;
  }
  .point-claim {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    .img-coin {
      max-width: 20px;
      justify-content: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      height: 100%;
      img {
        width: 100%;
        margin-top: 5px;
      }
    }
    span {
      color: #282828;
      text-align: center;
      font-family: "Coda Caption";
      font-size: 20.435px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%; /* 25.544px */
    }
  }
`

export const AllDailyInWeek = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
`;

export const DailyInWeek = styled.div<{ isActive?: Boolean }>`
  width: calc((100% - 60px) / 3);
  border-radius: 8px;
  border: ${({ isActive }) =>
    isActive ? '2px solid #ffc72e' : '2px solid #ffffff'};
  background: ${({ isActive }) => (isActive ? '#ff9d00' : '#A1A1A1')};
  min-height: 100px;
  box-shadow: 0px -6px 0px 0px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  position: relative;
  .img-check {
    max-width: 14px;
    position: absolute;
    top: -7px;
    right: -7px;
    img {
      width: 100%;
    }
  }
  &:last-child {
    width: calc(100% - 80px);
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  }
  .img-coins {
    max-width: 65px;
    img {
      width: 100%;
    }
  }
  h1 {
    color: #fff;
    text-align: center;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    font-family: 'ErasBoldITC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.56px;
    text-transform: uppercase;
  }
  h2 {
    color: ${({ isActive }) => (isActive ? ' #FFEA00' : '#DFDFDF')};
    text-align: center;
    text-shadow: ${({ isActive }) =>
      isActive ? ' 0px 3px 0px #482200' : '0px 3px 0px #000'};
    -webkit-text-stroke-width: 0.45729100704193115;
    -webkit-text-stroke-color: ${({ isActive }) =>
      isActive ? ' 0px 3px 0px #482200' : '0px 3px 0px #000'};
    font-family: 'ErasBoldITC';
    font-size: 18.814px;
    font-style: normal;
    font-weight: 400;
    line-height: 89%; /* 16.745px */
    letter-spacing: -1.129px;
    margin-top: -25px;
  }
`;
