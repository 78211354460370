import { configureStore } from "@reduxjs/toolkit";
import { useDispatch as useAppDispatch } from "react-redux";
import pointReducer from "./features/pointSlice";
import { authReducer } from "./features/authSlice";
import { dexApiV1 } from "../services/api/dexApiSlice";
import { authApiV1 } from "./api/authApiSlice";

export const store = configureStore({
  reducer: {
    point: pointReducer,
    auth: authReducer,
    [dexApiV1.reducerPath]: dexApiV1.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(dexApiV1.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const useDispatch = () => useAppDispatch<AppDispatch>();

export { useDispatch };
